import { getBlobUrl, getBlob } from "@/utils/apiHelpers";
import { ApiResponse, apiClient, PagedResponseWithMeta } from "./client";
import {
  NodeDefinition,
  TemplateControlKind,
  SparseTemplate,
  CaseFieldValues
} from "./types";
import { CustomerOrganization } from "./customerApi";

export const CaseUploadFileSizeLimit = 5242880;

export const isPdfConvertable = (type: string): boolean => {
  return ["docx", "pptx", "xlsx", "doc", "ppt", "xls"].includes(
    type?.toLowerCase()
  );
};

export const isPreviewableWithoutConversion = (type: string): boolean => {
  return ["jpeg", "jpg", "png", "pdf"].includes(type?.toLowerCase());
};

export const startContractCase =
  (organizationId: number | null, templateId: number) =>
  async (): Promise<ApiResponse<ContractCaseStarted>> => {
    const response = await apiClient.post("cases", {
      data: { organizationId, templateId }
    });

    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ContractCaseStarted };
    }
  };

export const getContractCases =
  (query: string) =>
  async (): Promise<
    ApiResponse<PagedResponseWithMeta<ContractCase, ContractCaseMeta>>
  > => {
    const response = await apiClient.get(`/cases/filter?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as PagedResponseWithMeta<
          ContractCase,
          ContractCaseMeta
        >
      };
    }
  };

export const upgradeCaseTemplate =
  (caseTemplateId: number) => async (): Promise<ApiResponse<boolean>> => {
    const response = await apiClient.post(
      `/cases/${caseTemplateId}/upgrade-templates`,
      {
        data: {}
      }
    );

    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as boolean };
    }
  };

export const getContractCasePdfLink =
  (id: number) => async (): Promise<ApiResponse<string>> => {
    const response = await apiClient.get(`/cases/${id}/pdf-link`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as string
      };
    }
  };

export const getContractCaseAppendixPdfLink =
  (caseId: number, appendixId: string) =>
  async (): Promise<ApiResponse<string>> => {
    const response = await apiClient.get(
      `/cases/${caseId}/pdf-link?appendix=${appendixId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as string
      };
    }
  };

export const getContractCaseDetails =
  (id: number) => async (): Promise<ApiResponse<CaseDetails>> => {
    const response = await apiClient.get(`/cases/${id}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as CaseDetails
      };
    }
  };

export const getContractCaseDetailsExternal =
  (id: number) => async (): Promise<ApiResponse<CaseDetails>> => {
    const response = await apiClient.get(`/cases/external/${id}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as CaseDetails
      };
    }
  };

export const saveContractCaseExternal =
  (dto: SaveCaseDto) => async (): Promise<ApiResponse<SavedCaseResponse>> => {
    const response = await apiClient.post(`/cases/external/save`, {
      data: dto,
      nonLoggedErrorCodes: [400]
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as SavedCaseResponse
      };
    }
  };

export const saveContractCase =
  (dto: SaveCaseDto) => async (): Promise<ApiResponse<SavedCaseResponse>> => {
    const response = await apiClient.post(`/cases/save`, {
      data: dto,
      nonLoggedErrorCodes: [400]
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as SavedCaseResponse
      };
    }
  };

export const tryLockContractCase =
  (dto: TryLockDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/cases/${dto.caseId}/trylock`, {
      data: dto,
      nonLoggedErrorCodes: [400]
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const unlockContractCase =
  (dto: UnlockDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/cases/${dto.caseId}/unlock`, {
      data: dto
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const tryLockContractCaseExternal =
  (dto: TryLockDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `/cases/external/${dto.caseId}/trylock`,
      {
        data: dto,
        nonLoggedErrorCodes: [400]
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const unlockContractCaseExternal =
  (dto: UnlockDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `/cases/external/${dto.caseId}/unlock`,
      {
        data: dto
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const getContractCasePreviewExternal =
  (data: CasePreviewDto) => async (): Promise<ApiResponse<null>> => {
    const newTab = window.open("", "_blank");
    newTab?.document.write("Skapar pdf . . . ");
    const response = await apiClient.post(
      `/cases/external/${data.caseId}/pdf`,
      {
        data: data,
        responseType: "blob"
      }
    );
    if (response.hasError) {
      newTab?.close();
      return { error: response.error };
    } else {
      if (newTab) newTab.location.href = getBlobUrl(response);

      return {
        result: null
      };
    }
  };

export const getContractCasePreview =
  (data: CasePreviewDto) => async (): Promise<ApiResponse<null>> => {
    const newTab = window.open("", "_blank");
    newTab?.document.write("Skapar pdf . . . ");

    const response = await apiClient.post(`/cases/${data.caseId}/pdf`, {
      data: data,
      responseType: "blob"
    });
    if (response.hasError) {
      newTab?.close();
      return { error: response.error };
    } else {
      if (newTab) newTab.location.href = getBlobUrl(response);
      return {
        result: null
      };
    }
  };

export const updateCaseStatus =
  (dto: UpdateCaseStatusDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/cases/${dto.caseId}/status`, {
      data: `"${dto.status}"`,
      nonLoggedErrorCodes: [400]
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const updateCaseStatusExternal =
  (dto: UpdateCaseStatusDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `/cases/external/${dto.caseId}/status`,
      {
        data: `"${dto.status}"`,
        nonLoggedErrorCodes: [400]
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const deleteContractCase =
  (caseId: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(`/cases/${caseId}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const getContractCaseUsers =
  (caseId: number) => async (): Promise<ApiResponse<ContractCaseUsers>> => {
    const response = await apiClient.get(`/cases/${caseId}/users`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as ContractCaseUsers
      };
    }
  };

export const shareContractCase =
  (caseId: number, users: string[], isPublic: boolean) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/cases/${caseId}/users`, {
      data: {
        users: users,
        isPublic
      }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const copyContractCase =
  (caseId: number) => async (): Promise<ApiResponse<number>> => {
    const response = await apiClient.post(`cases/${caseId}/copy`, {
      data: { destinationIsCaseTemplate: false }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as number };
    }
  };

export const copyPreCheckContractCase =
  (caseId: number) => async (): Promise<ApiResponse<CopyPreCheckResult>> => {
    const response = await apiClient.post(`cases/${caseId}/copy-pre-check`, {
      data: { destinationIsCaseTemplate: false }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CopyPreCheckResult };
    }
  };

export const getOrganizationsDestinationsThatCaseCanBeMovedTo =
  (caseId: number) =>
  async (): Promise<ApiResponse<CustomerOrganization[]>> => {
    const response = await apiClient.get(
      `/cases/${caseId}/organization-destinations`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as CustomerOrganization[]
      };
    }
  };

export const moveContractCaseToOtherOrganization =
  (caseId: number, organizationId: number) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/cases/${caseId}/move`, {
      data: {
        organizationId
      }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const getContractCaseTemplates =
  (organizationId: number, search: string) =>
  async (): Promise<ApiResponse<ContractCaseTemplate[]>> => {
    const response = await apiClient.get(
      `cases/templates?organizationId=${organizationId}&search=${search}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ContractCaseTemplate[] };
    }
  };

export const updateContractCaseTemplate =
  (templateId: number, name: string, description: string) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.patch(`cases/templates/${templateId}`, {
      data: { name, description }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const deleteContractCaseTemplate =
  (caseTemplateId: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(
      `cases/templates/${caseTemplateId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const copyContractCaseToCaseTemplate =
  (caseId: number, caseTemplateName: string, caseTemplateDescription: string) =>
  async (): Promise<ApiResponse<number>> => {
    const response = await apiClient.post(`cases/${caseId}/copy`, {
      data: {
        caseTemplateName,
        caseTemplateDescription,
        destinationIsCaseTemplate: true
      }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as number };
    }
  };

export const createCaseForAnonymousUser =
  (templateId: number) => async (): Promise<ApiResponse<CaseDetails>> => {
    const response = await apiClient.get(
      `/cases/create-anonymous/${templateId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as CaseDetails
      };
    }
  };

export const getTemplateVersionContractCasePreview =
  (templateId: number, versionId: number) =>
  async (): Promise<ApiResponse<CaseDetails>> => {
    const response = await apiClient.get(
      `/cases/preview-admin/${templateId}/versions/${versionId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CaseDetails };
    }
  };

export const downloadAppendixFile =
  (caseId: number, appendixId: string) =>
  async (): Promise<ApiResponse<string>> => {
    const response = await apiClient.get(
      `/cases/${caseId}/appendix/${appendixId}/file`,
      {
        responseType: "blob"
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: getBlobUrl(response)
      };
    }
  };

export const convertDocumentToPdf =
  (data: ConvertDocumentToPdfDto) => async (): Promise<ApiResponse<Blob>> => {
    const formData = new FormData();
    formData.append("uploadedFile", data.file, data.file.name);
    const response = await apiClient.post(
      `/cases/${data.caseId}/convert-document`,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData,
        responseType: "blob"
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: getBlob(response)
      };
    }
  };

export const convertDocumentToPdfExternal =
  (data: ConvertDocumentToPdfDto) => async (): Promise<ApiResponse<Blob>> => {
    const formData = new FormData();
    formData.append("uploadedFile", data.file, data.file.name);
    const response = await apiClient.post(
      `/cases/external/${data.caseId}/convert-document`,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData,
        responseType: "blob"
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: getBlob(response)
      };
    }
  };

export const getCasePdfForAnonymousUser =
  (templateId: number, data: SaveCaseDto) =>
  async (): Promise<ApiResponse<null>> => {
    const newTab = window.open("", "_blank");
    newTab?.document.write("Skapar pdf . . . ");
    const response = await apiClient.post(
      `/cases/create-anonymous/${templateId}/pdf`,
      {
        data: data,
        responseType: "blob"
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      if (newTab) newTab.location.href = getBlobUrl(response);
      return {
        result: null
      };
    }
  };

export type ContractCaseTemplate = {
  id: number;
  name: string;
  description: string;
  updatedBy: string;
  lastUpdated: Date;
};

export type ContractCaseUsers = {
  allUsers: ContractCaseUser[];
  addedUsers: string[];
  isPublic: boolean;
};

export type ContractCaseUser = {
  id: string;
  name: string;
};

export enum ContractCaseStatus {
  DRAFT = "Draft",
  DONE = "Done",
  CONCLUDED = "Concluded"
}

export enum ContractCaseSigningStatus {
  NONE = "None",
  SIGNING = "Signing",
  BOUNCED = "Bounced",
  REJECTED = "Rejected",
  SIGNED = "Signed",
  ABORTED = "Aborted",
  EXPIRED = "Expired"
}

export type ContractCaseMeta = {
  totalCaseCount: number;
  finishedCaseCount: number;
  myCaseCount: number;
  myFinishedCaseCount: number;
  signingCaseCount: number;
};

export type ContractCaseDetails = {
  id: number;
  templateVersion: {
    id: number;
    createdByName: string;
    createdDate: Date;
    version: number;
    header: NodeDefinition;
    sections: NodeDefinition[];
    footer: NodeDefinition;
  };
  logo: string;
  logoExtension: string;
  fields: ContractCaseFields;
};

export type CaseDetails = {
  id: number;
  caseId: string;
  logo: string;
  includeTableOfContents: boolean;
  logoExtension: string;
  defaultHelpText: string;
  appendices: CaseAppendix[];
  entityFields: EntityField[];
  status: ContractCaseStatus;
  signingStatus: ContractCaseSigningStatus;
  requiredEntityFields: number[];
  baseTemplateId: string;
  baseTemplateSortOrderOverride: number | null;
  updatedTimestamp: string;
  lockedByOtherUser: boolean;
  lockedByFullName: string;
  lockedDate: Date | null;
  isCaseTemplate: boolean;
  name: string;
  description: string;
};

export type CopyPreCheckResult = {
  upgradedTemplates: {
    id: string;
    templateName: string;
    templateReference: string;
  }[];
  removedEntityFields: string[];
};

export type CaseAppendix = CaseDetailTemplate | CaseUpload | CasePlaceholder;

export type CaseAppendixBase = {
  id: string;
  sortOrder: number;
  customAppendixNumber?: string;
};

export type CasePlaceholder = CaseAppendixBase & {
  kind: "Placeholder";
  name: string;
};

export type CaseUpload = CaseAppendixBase & {
  kind: "Upload";
  name: string;
  file: string;
  fileType: string;
};

export type CaseDetailTemplate = CaseAppendixBase & {
  kind: "Template";
  name: string;
  templateName: string;
  referenceNumber: string;
  defaultHelpText: string;
  templateId: number;
  version: number;
  header: NodeDefinition;
  sections: NodeDefinition[];
  footer: NodeDefinition;
  valueFields: ValueField[];
  relatedTemplates: SparseTemplate[];
};

export type ContractCase = {
  id: number;
  templateName: string;
  contractNumber: string;
  tenant: string;
  property: string;
  createdBy: string;
  created: string;
  updatedBy: string;
  updated: string;
  status: ContractCaseStatus;
  signed: string;
  secrecy: string;
  isPublic: boolean;
  signingStatus: ContractCaseSigningStatus;
  signingUpdatedDate: string;
};

export type Field = {
  readOnly: boolean;
  values: CaseFieldValues;
  valueKind: TemplateControlKind;
};

export type EntityField = Field & {
  entityNameId: number;
};

export type ValueField = Field & {
  name: string;
};

export type GroupedEntityFields = Record<string, EntityField | undefined>;

export type GroupedValueFields = Record<string, ValueField | undefined>;

export type ContractCaseFields = {
  entityFields: EntityField[];
  valueFields: ValueField[];
};

export type ContractCaseStarted = {
  contractCaseId: number;
  templateId: number;
  templateVersionId: number;
};

export type SaveCaseDto = {
  caseId: number;
  lastUpdatedTimestamp: string | null;
  forceUpdate: boolean;
  baseTemplateSortOrderOverride: number | null;
  entityFields: EntityField[];
  appendices: Appendix[];
  files?: [string: File];
};

export type TryLockDto = {
  caseId: number;
  lastUpdatedTimestamp: string | null;
  forceLock: boolean;
};

export type UnlockDto = {
  caseId: number;
};

export type SavedCaseResponse = {
  updatedTimestamp: string;
};

export type Appendix = TemplateAppendix | PlaceholderAppendix | FileAppendix;

export type AppendixBase = {
  id: string;
};

export type TemplateAppendix = AppendixBase & {
  kind: "Template";
  templateId: number | null;
  valueFields: ValueField[] | undefined;
};

export type PlaceholderAppendix = AppendixBase & {
  kind: "Placeholder";
};

export type FileAppendix = AppendixBase & {
  kind: "Upload";
  name: string;
};

export type CasePreviewDto = {
  caseId: number;
  entityFields: EntityField[];
  appendices: Appendix[];
};

export type UpdateCaseStatusDto = {
  caseId: number;
  status: ContractCaseStatus;
};

export type ConvertDocumentToPdfDto = {
  caseId: number;
  file: File;
};
