import { OkResponse } from "@/api/client/types";

export const getBlobUrl = (response: OkResponse): string => {
  const blob = new Blob([response.data as BlobPart], {
    type: "application/pdf"
  });
  const blobUrl = window.URL.createObjectURL(blob);
  return blobUrl;
};

export const getBlob = (
  response: OkResponse,
  type = "application/pdf"
): Blob => {
  const blob = new Blob([response.data as BlobPart], {
    type
  });
  return blob;
};

export const toBase64 = (fileOrBlob: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileOrBlob);
    reader.onload = () => resolve((reader.result as string).split(",")[1]);
    reader.onerror = error => reject(error);
  });

export const downloadBlob = (blob: Blob, fileName?: string): void => {
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName || "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => {
    window.URL.revokeObjectURL(blobUrl);
  }, 120 * 1000);
};
