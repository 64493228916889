import { toBase64 } from "@/utils/apiHelpers";
import { ApiResponse, apiClient, PagedResponse } from "./client";
import { Address, CustomerMembership } from "./customerApi";
import {
  TemplateStats,
  MonthStats,
  TemplateDetailsStats,
  UserOrganisationStats,
  CustomerContactStats,
  SigningCustomersStats,
  SigningTemplatesStats
} from "./types";

export const SAdminCommentFileSizeLimit = 50 * 1024 * 1024; // 50MB

export const getCustomer =
  (id: number) => async (): Promise<ApiResponse<AdminCustomer>> => {
    const response = await apiClient.get(`admin/customers/${id}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as AdminCustomer };
    }
  };

export const setCustomerBlockedStatus =
  (dto: SetCustomerBlockedStatusDto) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.put(
      `admin/customers/${dto.customerId}/blocked`,
      {
        data: dto
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getSubscriptions =
  (query: string) =>
  async (): Promise<ApiResponse<PagedResponse<SubscriptionItem>>> => {
    const response = await apiClient.get(`/admin/subscriptions?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as PagedResponse<SubscriptionItem> };
    }
  };

export const getSubscriptionsAsCsv =
  (query: string) => async (): Promise<ApiResponse<Blob>> => {
    const response = await apiClient.get(`/admin/subscriptions?${query}`, {
      responseType: "blob"
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getInvoicePurchases =
  (query: string) =>
  async (): Promise<ApiResponse<PagedResponse<PurchasesInvoiceItem>>> => {
    const response = await apiClient.get(`admin/purchases/invoice?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as PagedResponse<PurchasesInvoiceItem> };
    }
  };

export const getInvoicePurchasesAsCsv =
  (query: string) => async (): Promise<ApiResponse<Blob>> => {
    const response = await apiClient.get(`admin/purchases/invoice?${query}`, {
      responseType: "blob"
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getCreditCardPurchases =
  (query: string) =>
  async (): Promise<ApiResponse<PagedResponse<CreditCardPaymentsItem>>> => {
    const response = await apiClient.get(`admin/purchases/creditcard?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as PagedResponse<CreditCardPaymentsItem> };
    }
  };

export const getCreditCardPurchasesAsCsv =
  (query: string) => async (): Promise<ApiResponse<Blob>> => {
    const response = await apiClient.get(
      `admin/purchases/creditcard?${query}`,
      { responseType: "blob" }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const addSAdminComment =
  (dto: SAdminCommentDto, file?: File) =>
  async (): Promise<ApiResponse<null>> => {
    if (file) {
      dto.file = await toBase64(file);
      dto.fileName = file.name;
    }
    const response = await apiClient.post(`admin/comments`, {
      data: dto
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const updateSAdminComment =
  (dto: SAdminCommentDto, file?: File) =>
  async (): Promise<ApiResponse<null>> => {
    if (!dto.commentId) {
      return { error: { message: "commentId is required" } };
    }
    if (file) {
      dto.file = await toBase64(file);
      dto.fileName = file.name;
    }
    const response = await apiClient.patch(`admin/comments/${dto.commentId}`, {
      data: dto
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const deleteSAdminComment =
  (commentId: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(`admin/comments/${commentId}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getSAdminComments =
  (
    commentedObjectId: string,
    commentType: SAdminCommentType,
    page = 1,
    pageSize = 1000
  ) =>
  async (): Promise<ApiResponse<PagedResponse<SAdminCommentItem>>> => {
    const query = new URLSearchParams({
      commentedObjectId,
      type: commentType,
      page: page.toString(),
      pageSize: pageSize.toString()
    });

    const response = await apiClient.get(`admin/comments?${query.toString()}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as PagedResponse<SAdminCommentItem> };
    }
  };

export const getSAdminCommentsCount =
  (commentedObjectId: string, commentType: SAdminCommentType) =>
  async (): Promise<ApiResponse<SAdminCommentCount>> => {
    const response = await apiClient.get(
      `admin/comments/count?commentedObjectId=${commentedObjectId}&commentType=${commentType}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as SAdminCommentCount };
    }
  };

export const downloadSAdminCommentFile = async (
  commentId: number
): Promise<ApiResponse<Blob>> => {
  const response = await apiClient.get(`admin/comments/${commentId}/file`, {
    responseType: "blob"
  });
  if (response.hasError) {
    return { error: response.error };
  } else {
    return { result: response.data as Blob };
  }
};

export const updateUserDetails =
  (userId: string, command: UpdateUserDetailsCommand) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.patch(`admin/users/${userId}`, {
      data: command
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const deleteUser =
  (userId: string, password: string, confirmUserName: string) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`admin/users/${userId}/delete`, {
      data: { password, confirmUserName }
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const addUserToOrganization =
  (organizationId: number, userId: string) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `admin/organizations/${organizationId}/users`,
      {
        data: { userId }
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getTemplateStats =
  (daysback: number, customerId?: number, organizationId?: number) =>
  async (): Promise<ApiResponse<Array<TemplateStats>>> => {
    let url = `admin/stats/templates?daysback=${daysback}`;
    if (customerId) url += `&customerId=${customerId}`;
    if (organizationId) url += `&organizationId=${organizationId}`;
    const response = await apiClient.get(url);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Array<TemplateStats> };
    }
  };

export const getTemplateStatsWithDates =
  (
    dateFrom: string,
    dateUntil: string,
    customerId?: number,
    organizationId?: number
  ) =>
  async (): Promise<ApiResponse<Array<TemplateStats>>> => {
    let url = `admin/stats/templates-dates?from=${dateFrom}&until=${dateUntil}`;
    if (customerId) url += `&customerId=${customerId}`;
    if (organizationId) url += `&organizationId=${organizationId}`;
    const response = await apiClient.get(url);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Array<TemplateStats> };
    }
  };

export const getTemplateStatsWithDatesCsv =
  (
    dateFrom: string,
    dateUntil: string,
    customerId?: number,
    organizationId?: number
  ) =>
  async (): Promise<ApiResponse<Blob>> => {
    let url = `admin/stats/templates-dates?from=${dateFrom}&until=${dateUntil}&export=true`;
    if (customerId) url += `&customerId=${customerId}`;
    if (organizationId) url += `&organizationId=${organizationId}`;
    const response = await apiClient.get(url, { responseType: "blob" });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getTemplateDetailStats =
  (templateId: number, dateFrom: string, dateUntil: string) =>
  async (): Promise<ApiResponse<Array<TemplateDetailsStats>>> => {
    const url = `admin/stats/templates/${templateId}?from=${dateFrom}&until=${dateUntil}`;
    const response = await apiClient.get(url);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Array<TemplateDetailsStats> };
    }
  };

export const getTemplateDetailStatsCsv =
  (templateId: number, dateFrom: string, dateUntil: string) =>
  async (): Promise<ApiResponse<Blob>> => {
    const url = `admin/stats/templates/${templateId}?from=${dateFrom}&until=${dateUntil}&export=true`;
    const response = await apiClient.get(url, { responseType: "blob" });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getUserOrganisationReport =
  (query: string) =>
  async (): Promise<ApiResponse<PagedResponse<UserOrganisationStats>>> => {
    const url = `admin/stats/userorganisations?${query}`;
    const response = await apiClient.get(url);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as PagedResponse<UserOrganisationStats> };
    }
  };

export const getUserOrganisationReportCsv =
  (membership?: string) => async (): Promise<ApiResponse<Blob>> => {
    const url = `admin/stats/userorganisations?export=true&membership=${
      membership || ""
    }`;
    const response = await apiClient.get(url, { responseType: "blob" });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getCustomerContactsReport =
  (query: string) =>
  async (): Promise<ApiResponse<PagedResponse<CustomerContactStats>>> => {
    const url = `admin/stats/customercontacts?${query}`;
    const response = await apiClient.get(url);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as PagedResponse<CustomerContactStats> };
    }
  };

export const getCustomerContactsReporCsv =
  (membership?: string) => async (): Promise<ApiResponse<Blob>> => {
    const url = `admin/stats/customercontacts?export=true&membership=${
      membership || ""
    }`;
    const response = await apiClient.get(url, { responseType: "blob" });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getCustomerDashboardStats =
  (customerId: number) => async (): Promise<ApiResponse<DashboardStats>> => {
    const response = await apiClient.get(
      `admin/customers/${customerId}/stats/dashboard`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as DashboardStats };
  };

export const moveOrganizationToCustomer =
  (organizationId: number, targetCustomerId: number) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.patch(
      `admin/organizations/${organizationId}/move`,
      { data: { targetCustomerId } }
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: null };
  };

export const getOrganizationDashboardStats =
  (organizationId: number) =>
  async (): Promise<ApiResponse<DashboardStats>> => {
    const response = await apiClient.get(
      `/admin/organizations/${organizationId}/stats/dashboard`
    );
    if (response.hasError) {
      return { error: response.error };
    }
    return { result: response.data as DashboardStats };
  };

export const getObjectCreatedMonthlyStats =
  (year: number) => async (): Promise<ApiResponse<ObjectsCreatedMonthly>> => {
    const response = await apiClient.get(
      `admin/stats/ObjectCreatedPerMonth?year=${year}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ObjectsCreatedMonthly };
    }
  };

export const getOngoingCaseSignings =
  () => async (): Promise<ApiResponse<OngoingCaseSignings>> => {
    const response = await apiClient.get(`admin/ongoing-case-signings`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as OngoingCaseSignings };
    }
  };

export const setInvoiceComment =
  (invoiceId: number, commentText: string) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `admin/purchases/invoice/${invoiceId}/comment`,
      { data: { commentText } }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const refreshOngoingCaseSignings =
  () => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `admin/refresh-ongoing-case-signings`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getSigningCustomersStatsWithDates =
  (
    dateFrom: string,
    dateUntil: string,
    customerId?: number,
    organizationId?: number
  ) =>
  async (): Promise<ApiResponse<Array<SigningCustomersStats>>> => {
    let url = `admin/stats/signing-customers?from=${dateFrom}&until=${dateUntil}`;
    if (customerId) url += `&customerId=${customerId}`;
    if (organizationId) url += `&organizationId=${organizationId}`;
    const response = await apiClient.get(url);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Array<SigningCustomersStats> };
    }
  };

export const getSigningCustomersStatsWithDatesCsv =
  (
    dateFrom: string,
    dateUntil: string,
    customerId?: number,
    organizationId?: number
  ) =>
  async (): Promise<ApiResponse<Blob>> => {
    let url = `admin/stats/signing-customers?from=${dateFrom}&until=${dateUntil}&export=true`;
    if (customerId) url += `&customerId=${customerId}`;
    if (organizationId) url += `&organizationId=${organizationId}`;
    const response = await apiClient.get(url, { responseType: "blob" });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export const getSigningTemplatesStatsWithDates =
  (
    dateFrom: string,
    dateUntil: string,
    customerId?: number,
    organizationId?: number
  ) =>
  async (): Promise<ApiResponse<Array<SigningTemplatesStats>>> => {
    let url = `admin/stats/signing-templates?from=${dateFrom}&until=${dateUntil}`;
    if (customerId) url += `&customerId=${customerId}`;
    if (organizationId) url += `&organizationId=${organizationId}`;
    const response = await apiClient.get(url);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Array<SigningTemplatesStats> };
    }
  };

export const getSigningTemplatesStatsWithDatesCsv =
  (
    dateFrom: string,
    dateUntil: string,
    customerId?: number,
    organizationId?: number
  ) =>
  async (): Promise<ApiResponse<Blob>> => {
    let url = `admin/stats/signing-templates?from=${dateFrom}&until=${dateUntil}&export=true`;
    if (customerId) url += `&customerId=${customerId}`;
    if (organizationId) url += `&organizationId=${organizationId}`;
    const response = await apiClient.get(url, { responseType: "blob" });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as Blob };
    }
  };

export type SubscriptionItem = {
  id: number;
  name: string;
  organizationNumber: string;
  membership: string;
  subscription: string;
  nextSubscription: string;
  subscriptionStarts?: Date;
  subscriptionRenews?: Date;
  remainingQuota: number;
  blocked: boolean;
};

export type AdminCustomer = {
  id: number;
  name: string;
  organizationNumber: string;
  phoneNumber: string;
  remainingQuota: number;
  invoiceEmail: string;
  invoiceReference: string;
  address: Address;
  invoiceAddress: Address | null;
  membership: CustomerMembership | null;
  blocked: boolean;
};

export type DashboardStats = {
  start: { year: number; month: number };
  until: { year: number; month: number };
  casesPerMonth: MonthStats[];
  totalCasesCreated: number;
  payedTemplatesPerMonth: MonthStats[];
  totalNumberOfPayedTemplates: number;
};

export type ObjectsCreatedMonthly = {
  start: { year: number; month: number };
  until: { year: number; month: number };
  cases: MonthStats[];
  users: MonthStats[];
  customers: MonthStats[];
  organizations: MonthStats[];
  cardPayments: MonthStats[];
  subscriptionPurchasesNew: MonthStats[];
  subscriptionPurchasesRenewed: MonthStats[];
  totalCases: number;
  totalUsers: number;
  totalCustomers: number;
  totalCardPayments: number;
  totalOrganizations: number;
  totalSumSubscriptions: number;
};

export type UpdateUserDetailsCommand = {
  firstName: string;
  lastName: string;
  email: string;
};

export type SAdminCommentType = "Customer" | "User" | "Organization";
export type SAdminCommentCount = { count: number };

export type SAdminCommentDto = {
  commentId?: number;
  text: string;
  commentedObjectId?: string;
  type?: SAdminCommentType;
  file?: string;
  fileName?: string;
};

export type SAdminCommentItem = {
  id: number;
  text: string;
  lastUpdate: Date;
  updatedBy: string;
  hasFile: boolean;
  fileName?: string;
  fileExtension?: string;
};

export type PurchasesInvoiceItem = {
  id: number;
  date: Date;
  name: string;
  organizationNumber: string;
  membership: string;
  addressStreet: string;
  addressCity: string;
  addressZipCode: string;
  addressCountry: string;
  phoneNumber: string;
  invoiceReference: string;
  invoiceEmail: string;
  amount: number;
  description: string;
  type: string;
  comment?: string;
};

export type CreditCardPaymentsItem = {
  id: number;
  date: Date;
  customer: string;
  organizationNumber: string;
  membership: string;
  amount: number;
  description: string;
  type: string;
  userName: string;
  reference: string;
  status: string;
};

export type SetCustomerBlockedStatusDto = {
  customerId: number;
  blocked: boolean;
};

export type OngoingCaseSignings = {
  ongoing: number;
  ongoingNotUpdatedTheLastDay: number;
};
