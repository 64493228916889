{
  "common": {
    "noValue": "Inget val",
    "phoneNumber": "Telefonnummer",
    "email": "Email",
    "password": "Lösenord",
    "loading": "Laddar...",
    "error": "Något gick fel:",
    "yes": "Ja",
    "no": "Nej",
    "cancel": "Avbryt",
    "help": "Hjälp",
    "myOrganization": "Organisation",
    "myProfile": "Mitt användarkonto",
    "brandName": "Fastighetsägarna",
    "customer": "Kund",
    "roleOwner": "Organisationsägare",
    "roleAdmin": "Administratör",
    "roleUser": "Användare",
    "dataError": "Något gick fel vid hämtning. Om problemet kvarstår kontakta support.",
    "sign": "E-signera",
    "templateTagNew": "Nyhet",
    "templateTagFree": "Kostnadsfri",
    "templateTagPopular": "Populär",
    "templateTagEnglish": "Engelska",
    "draft": "Utkast",
    "done": "Skarpt",
    "concluded": "Avslutat",
    "signingStatus": "E-signeras",
    "signedStatus": "E-signerat",
    "rejectedStatus": "Avvisat",
    "abortedStatus": "Återkallat",
    "expiredStatus": "Förfallit",
    "noneStatus": "Ej skickat",
    "sentStatus": "Skickat",
    "bouncedStatus": "Leveransfel",
    "automaticDeletionInfo": "Ärenden raderas automatiskt efter en viss tid.",
    "automaticDeletionInfoLink": "Gallringstider",
    "draftStatusTitle": " När ärendet är ett utkast skrivs ärendet ut med vattenstämpel. Ärendet kommer automatiskt att raderas 12 månader efter sista ändringen.",
    "doneStatusTitle": "Ärendet är skarpt och kan skrivas ut utan utkaststämpel. Ärendet kommer avslutas 12 månader efter sista ändringen.",
    "concludedStatusTitle": "Ärendet är avslutat och kan inte redigeras. Ärendet kommer raderas 6 månader efter att det avslutats.",
    "signingStatusTitle": "Ärendet är skickat för e-signering",
    "rejectedStatusTitle": "En begäran om att e-signera har avvisats",
    "signedStatusTitle": "Ärendet är e-signerat. Ärendet kommer raderas 6 månader efter att det skickats för e-signering.",
    "abortedStatusTitle": "E-signeringen har återkallats",
    "expiredStatusTitle": "E-signeringen har förfallit",
    "noneStatusTitle": "Ärendet har ännu inte skickats för e-signering",
    "bouncedStatusTitle": "En begäran om att e-signera kunde inte levereras",
    "exclamation": "!",
    "delete": "Radera",
    "remove": "Ta bort",
    "update": "Uppdatera",
    "add": "Lägg till",
    "ok": "Ok",
    "close": "Stäng"
  },
  "baseToast": {
    "close": "Close"
  },
  "theUserNavbar": {
    "customerDetails": "Kundadministration",
    "organizationDetails": "Organisationsinställningar",
    "createCustomerAndOrganization": "Lägg till kundkonto",
    "logOut": "Logga ut",
    "templates": "Dokument",
    "contract-cases": "Mina ärenden",
    "news": "Aktuellt",
    "faq": "Frågor & Svar",
    "pricing": "Priser",
    "profileImage": "Profilbild",
    "noOrganization": "Ingen organisation",
    "logInOrRegister": "Logga in/registrera",
    "support": "Support 0771 - 18 18 58",
    "orgAdminNavTooltip": "Organisationsinställningar",
    "customerAdminNavTooltip": "Kundinställningar",
    "orgGroupHeading": "Organisationer",
    "customerLabel": "Kund"
  },
  "theAdminNavbar": {
    "customers": "Kunder",
    "organizations": "Organisationer",
    "templateEditor": "Malleditor",
    "logOut": "Logga ut",
    "templates": "Mallar",
    "users": "Användare",
    "purchases": "Försäljning",
    "stats": "Statistik",
    "reports": "Rapporter"
  },
  "editorSaveEditTemplate": {},
  "loginPage": {
    "logIn": "Logga in",
    "register": "Registrera dig",
    "forgotPassword": "Glömt lösenord?",
    "noAccount": "Har du inget konto än?",
    "loginFailed": "Nånting gick fel vid inloggning",
    "loggingIn": "Loggar in...",
    "loginError": "Försök igen",
    "loginErrorTitle": "Inloggning misslyckades",
    "emailMissing": "Ange epost",
    "passwordMissing": "Ange lösenord",
    "infoText1": "Har du fått en inbjudan mejlad till dig? Vänligen logga in för att acceptera eller avvisa den.",
    "registerInfoText": "Registrera dig och kom igång med Fastighetsägarna Dokument idag!",
    "accountActivatedSuccess": "Kontot är aktiverat, vänligen logga in",
    "accountActivatedError": "Aktiveringsmailet är gammalt, vänligen använd det senaste eller registrera kontot igen"
  },
  "adminCustomerPage": {
    "tabSubscription": "Abonnemang",
    "tabUsageStats": "Användning",
    "tabManageCustomer": "Hantera"
  },
  "adminCustomerSubscriptionPage": {
    "header": "Ändra abonnemangsuppgifter",
    "remainingQuotaLabel": "Saldo*"
  },
  "adminOrganizationPage": {
    "header": "Organisationsadministration",
    "tabEditDetails": "Organisationsuppgifter",
    "tabListUsers": "Användare",
    "tabListInvites": "Inbjudningar",
    "tabManageOrganization": "Hantera",
    "tabUsageStats": "Användning"
  },
  "organizationInvitesTab": {
    "noInvites": "Det finns inga inbjudningar till denna organisation"
  },
  "editorSaveTemplateMetadata": {
    "saveTemplate": "Spara mall",
    "updateTemplate": "Uppdatera version",
    "templateName": "Mallnamn",
    "templateReferenceNumber": "Referensnummer",
    "price": "Pris",
    "description": "Beskrivning",
    "defaultHelpText": "Generell handledningstext",
    "attachments": "Bilagor",
    "saving": "Sparar...",
    "updating": "Uppdaterar...",
    "categories": "Kategorier",
    "categorySelectPlaceholder": "Välj kategorier",
    "attachmentSelectPlaceholder": "Välj mall",
    "multiNoResults": "Oops! Inga resultat, försök ändra söktermen.",
    "comment": "Kommentar",
    "tags": "Taggar",
    "tagsPlaceholder": "Välj tagg",
    "allowBeingAddedMultipleTimes": "Tillåt flera instanser av denna bilagan",
    "signingRestrictions": "Begräsningar för signering",
    "signingRestrictionNone": "Inga begräsningar",
    "signingRestrictionOnlyAsMain": "Bara som huvudavtal",
    "signingRestrictionNever": "Får inte signeras"
  },
  "createOrganizationPage": {
    "street": "Adress",
    "zipCode": "Postnummer",
    "createOrganization": "Skapa organisation",
    "createOrganizationInfoText": "Du är på väg att lägga till en ny organisation. När du lägger till organisationen kommer du automatiskt bli tilldelad rollen ägare och vara den enda som har tillgång till den. När organisationen är tillagd kommer du kunna bjuda in andra användare och fördela behörigheter. Organisationen kommer att tillhöra det existerande kundkontot",
    "createCustomer": "Skapa kundkonto",
    "organization": "Organisation",
    "organizationName": "Företagsnamn/Namn",
    "organizationNumber": "Organisations- eller personnummer",
    "customerDetails": "Kunduppgifter",
    "customerName": "Kundnamn",
    "existingCustomer": "Existerande kund",
    "membership": "Medlem",
    "membershipNumber": "Medlemsnummer",
    "chooseCustomerInvoiceAddress": "Ange faktureringsadress om annan än ovan",
    "customerAddress": "Adress",
    "customerInvoiceAddress": "Faktureringsadress",
    "city": "Postort",
    "country": "Land",
    "countryLabel": "Land (Om annat än Sverige)",
    "create": "Skapa",
    "add": "Lägg till",
    "created": "Organisation skapad",
    "creating": "Skapar...",
    "adding": "Lägger till...",
    "invoiceEmail": "E-postadress för faktura",
    "invoiceReference": "Fakturareferens",
    "organizationNameMissing": "Du måste ange ett namn på organisationen.",
    "organizationNumberMissing": "Du måste ange ett organisationsnummer.",
    "customerNameMissing": "Du måste ange ett namn på kunden.",
    "membershipNumberMissing": "Du måste ange ett medlemsnummer.",
    "addressStreetMissing": "Du måste ange en adress.",
    "addressZipCodeMissing": "Du måste ange ett postnummer.",
    "addressCityMissing": "Du måste ange en postort.",
    "organizationNumberInvalid": "Ska anges i formatet 123456-7890",
    "invoiceReferenceMissing": "Du måste ange fakturareferens",
    "errorBlocked": "Du kan inte skapa ett kundkonto med angivet organisationsnummer. Kontakta supporten för mer information.",
    "errorUnknown": "Något gick fel. Försök igen eller kontakta supporten vid fortsatt fel."
  },
  "createOrganizationModal": {
    "headingNoCurrentCustomer": "Hej",
    "headingCurrentCustomer": "Lägg till organisation",
    "welcomeMessage": "Välkommen till Fastighetsägarna Dokument! Du är alldeles strax redo att börja skapa kontrakt, men för att kunna göra det behöver du först skapa ett kundkonto."
  },
  "registerPage": {
    "registerNewUser": "Registrera ny användare",
    "firstName": "Förnamn",
    "surname": "Efternamn",
    "repeatPassword": "Upprepa lösenord",
    "createAccount": "Skapa konto",
    "creatingAccount": "Skapar...",
    "nameMissing": "Ange namn",
    "surnameMissing": "Ange efternamn",
    "emailMissing": "Ange epost",
    "invalidEmail": "Felaktig epost",
    "passwordMissing": "Ange lösenord",
    "passwordLength": "Lösenordet måste vara minst 6 tecken långt",
    "passwordMismatch": "Lösenorden matchar inte",
    "created": "Konto skapat, aktivera konto via din angivna email",
    "failed": "Något gick fel",
    "terms": "Genom att registrera dig godkänner du",
    "termsTitle": "våra användarvillkor",
    "termsLink": "https://www.fastighetsagarna.se/tjanster/dokument/allmanna-villkor-fastighetsagarna-dokument/",
    "cancelInvitation": "Avbryt",
    "accountActivatedSuccess": "Kontot är aktiverat, vänligen logga in",
    "accountActivatedError": "Aktiveringsmailet är gammalt, vänligen använd det senaste eller registrera kontot igen"
  },
  "confirmEmailSentPage": {
    "confirmEmail": "Bekräfta e-postadress",
    "infoText1": "Du har fått ett e-postmeddelande med en aktiveringslänk, vänliga kolla din e-post.",
    "infoText2": "Om du upplever att e-postmeddelandet inte kommit fram kan du prova att titta i din skräppost."
  },
  "confirmEmailPage": {
    "confirmEmail": "Din e-postadress behöver bekräftas",
    "infoText1": "Du har tidigare fått ett e-postmeddelande med en aktiveringslänk, vänliga kolla din e-post.",
    "infoText2": "Om du upplever att e-postmeddelandet inte kommit fram kan du prova att titta i din skräppost.",
    "infoText3": "Om du ändå inte hittar e-postmeddelandet kan du omregistrera din användare.",
    "registerAction": "Omregistrera användare"
  },
  "unauthorizedPage": {
    "accessDenied": "Åtkomst nekad",
    "notAuthorized": "Du har inte rätt behörighet för att visa denna sida."
  },
  "confirmDialog": {
    "unsavedChanges": "Du har osparade ändringar",
    "confirm": "Är du säker på att du vill gå vidare utan att spara?",
    "confirmText": "Stäng utan att spara",
    "declineText": "Fortsätt redigera"
  },
  "templatesPage": {
    "heroTitle": "Välkommen till Fastighetsägarna Dokument<br />-tryggt, snabbt, enkelt",
    "heroContent": "I vår digitala tjänst finns alla dokument du i fastighetsbranschen behöver. Skapa ett konto och skriv dina avtal direkt online. Vi gör det enkelt att göra rätt!",
    "coockieLink": "<a href='https://www.fastighetsagarna.se/tjanster/dokument/integritets--och-cookiepolicy-fastighetsagarna-dokument/' target='_blank'>Integritets- och coockiepolicy</a>",
    "copyright-text": "© Copyright 2022 Fastighetsägarna Sverige AB.",
    "show": "Visa",
    "document": "Dokument",
    "categories": "Kategorier",
    "categorySelectPlaceholder": "Filtrera kategori",
    "categorySelectLimitLabel": "och {count} till",
    "tableNoItems": "Inga dokument",
    "tableNoMatches": "Inga dokument matchar sökningen",
    "searchPlaceholderText": "Sök dokument...",
    "invitationBanner": "Du har accepterat inbjudan till {organizationName} och har nu tillgång till organisationens dokument.",
    "close": "Stäng",
    "fetchFailed": "Hämtning misslyckades,",
    "pleaseTryAgain": "försök gärna igen",
    "listHeader": "Välj dokument",
    "caseTemplates": "Mallärenden",
    "details": "Detaljer",
    "use": "Använd",
    "failedToStartTitle": "Ärende kunde inte startas",
    "failedToStartText": "Försök igen",
    "clearFilter": "Rensa filter",
    "creatingCase": "Skapar...",
    "changeEmailSucces": "Email är nu ändrad",
    "changeEmailError": "Något gick fel vid uppdatering av email"
  },
  "adminTemplatesPage": {
    "templates": "Mallar",
    "newTemplate": "Skapa ny mall",
    "categorySelectPlaceholder": "Filtrera på kategori",
    "categorySelectLimitLabel": "och {count} till",
    "tableNoItems": "Inga mallar",
    "tableNoMatches": "Inga mallar matchar sökningen",
    "searchPlaceholderText": "Sök mallar...",
    "confirmDeleteTitle": "Radera mall",
    "confirmDeleteMessage": "Är du säker på att du vill radera denna mall? Denna åtgärd kan inte ångras."
  },
  "adminPreviewTemplatePage": {
    "fetchingTemplate": "Hämtar mall...",
    "fetchErrorMessage": "Något gick fel vid hämtning av mallen"
  },
  "adminCustomersPage": {
    "title": "Kunder",
    "tableNoItems": "Ingen data",
    "tableNoMatches": "Inga kunder matchar sökningen",
    "searchPlaceholderText": "Sök kund..."
  },
  "adminOrganizationsPage": {
    "title": "Organisationer",
    "tableNoItems": "Ingen data",
    "tableNoMatches": "Inga organisationer matchar sökningen",
    "searchPlaceholderText": "Sök organisation..."
  },
  "templateMetadataModal": {
    "version": "Version",
    "createdBy": "Skapad av",
    "active": "Aktiv",
    "action": "Åtgärd",
    "preview": "Förhandsgranska",
    "downloadPdf": "Ladda ner pdf",
    "publish": "Publicera",
    "unpublish": "Avpublicera",
    "date": "Datum",
    "error": "Något gick fel vid hämtning"
  },
  "startContractModal": {
    "start": "Starta ärende",
    "loginRequired": "Du måste vara inloggad för att ta del av detta dokument",
    "missingDescription": "Dokumentet saknar beskrivning",
    "appendixHeading": "Bilagor som kan läggas till när du startat ärendet",
    "starting": "Startar ärende...",
    "loginButton": "Logga in / Registrera",
    "creatingPreview": "Skapar förhandsgranskning...",
    "createPreview": "Förhandsgranska",
    "previewFailed": "Förhandsgranskning misslyckades",
    "startFailed": "Start av ärende misslyckades",
    "loginNotRequiredFreeTemplate": "Det här dokumentet är gratis och du kan använda det utan att logga in",
    "startFree": "Använd gratis"
  },
  "editTemplatePage": {
    "loadingTemplate": "Loading...",
    "errorLoadingTemplate": "Error:"
  },
  "editContractPage": {
    "placeholder": "Bilaga som ska läggas till senare",
    "customTextPlaceholder": "Skriv fritext här...",
    "final": "Skarpt",
    "finalizeTitle": "Markera ärende som skarpt",
    "finalizeConfirmation": "Om du skarpmarkerar ärendet kan du skriva ut formuläret utan utkaststämpel. Vill du fortsätta?",
    "conclude": "Avsluta ärende",
    "concludeTitle": "Avsluta ärende",
    "concludeConfirmation": "Är du säker på att du vill avsluta ärendet? När ärendet är avslutat kan du inte längre göra ändringar.",
    "validationTitle": "Kontraktsnummer saknas",
    "validationError": "Du måste fylla i ett kontraktsnummer innan du kan gå vidare.",
    "statusChangeError": "Statusförändringen misslyckades",
    "customerBlockedError": "Kundkontot är inaktiverat och ärenden kan inte skarpmarkeras. Vänligen kontakta Fastighetsägarna dokument support.",
    "error": "Något gick fel",
    "close": "Stäng",
    "loadingContractCase": "Loading...",
    "errorLoadingContractCase": "Error:",
    "caseTemplates": "Bilagor",
    "pdf": "PDF",
    "saveError": "Det gick inte att spara.",
    "saveSuccess": "Alla ändringar har sparats",
    "previewError": "Förhandsgranskning misslyckades. Meddelande: ",
    "couldNotConvertFileError": "Filen kunde inte konverteras. Om filen är en av de filtyper som stöds (PDF, PNG, JPG, JPEG, DOC, DOCX, XLS, XLSX, PPT, PPTX), prova att ladda upp den på nytt.",
    "save": "Spara",
    "print": "Skriv ut",
    "finalize": "Skarpmarkera",
    "signingOngoing": "E-signering pågår",
    "setupSigningAction": "Starta e-signering",
    "manageSigningAction": "Hantera e-signering",
    "viewSigningAction": "Visa e-signering",
    "helpTexts": "Handledning",
    "helpTextMessage1": "Tryck på",
    "helpTextMessage2": "jämte avsnittsrubriken i dokumentet för att få handledning",
    "helpTextNotChosenTitle": "Ingen handledning vald",
    "helpTextDoesNotExistTitle": "Ingen handledning finns för vald sektion",
    "toggleAddAction": "Lägg till",
    "hideAdd": "Dölj lägg till",
    "deleteAppendixTitle": "Ta bort bilaga",
    "deleteAppendixConfirmation": "Är du säker på att du vill ta bort bilagan?",
    "pdfUploadEncrypted": "En eller flera av dina uppladdade PDF-filer är krypterade. Krypterade PDF-filer stöds ej.",
    "fileTooLarge": "En eller flera av dina uppladdade filer är för stora. En uppladdad fil får inte vara större än 5MB.",
    "fileTypeInvalid": "En eller flera av dina uppladdade filer är av fel typ. Tillåtna filtyper är PDF, PNG, JPG, JPEG, DOC, DOCX, XLS, XLSX, PPT och PPTX.",
    "serverError": "Servern kunde inte kontaktas, försök igen eller hämta om ärendet.",
    "editingOngoingTitle": "Redigering pågår",
    "editingOngoingBanner": "{name} redigerar ärendet",
    "editAnywayMessage": "{name} har osparade ändringar i detta ärende. Om du redigerar ändå riskerar dessa ändringar att gå förlorade.",
    "editAnywayAction": "Redigera ändå",
    "editLaterAction": "Jag redigerar senare",
    "editingConflictTitle": "Redigeringskonflikt",
    "editingConflictMessage": "Ärendet har ändrats sedan du senast hämtade det. Om du sparar nu så riskerar dessa ändringar att gå förlorade.",
    "saveAnywayMessage": "{name} har osparade ändringar i detta ärende. Om du sparar nu så riskerar dessa ändringar att gå förlorade.",
    "overwriteAction": "Spara",
    "discardChangesAction": "Släng mina ändringar",
    "reloadNeededTitle": "Ny hämtning krävs",
    "reloadNeededMessage": "Ärendet har ändrats sedan du senast hämtade det. Du måste hämta den senaste versionen av ärendet för att kunna redigera det.",
    "reloadAction": "Hämta den senaste versionen",
    "deleteAction": "Radera",
    "renameAction": "Ändra namn",
    "changeAppendixSuffix": "Ändra nummer",
    "renameAppendixTitle": "Ändra namn",
    "appendixNameLabel": "Namn",
    "appendixNamePlaceholder": "Lämna blankt för att återgå till originalnamnet",
    "appendixUploadNamePlaceholder": "Namn på filbilagan",
    "updateFileAction": "Uppdatera fil",
    "replacePlaceholderAction": "Ersätt med fil",
    "externalNotEnoughQuotaAction": "OK",
    "externalNotEnoughQuotaTitle": "Aj då!",
    "externalNotEnoughQuotaMessage": "Ert abonnemang tillåter inte detta. Kontakta er administratör.",
    "getSignedCasePdf": "Ladda ner signerat dokument",
    "getSignedCasePdfError": "Det gick inte att ladda ner det signerade dokumentet från signeringstjänsten. Försök igen om en stund.",
    "getSignedCasePdfNoLongerAvailable": "Det signerade dokumentet är inte längre tillgängligt för nedladdning från signeringstjänsten.",
    "changeAppendixNumberTitle": "Ändra bilagenummer",
    "changeAppendixNumberPlaceholder": "Bilagenummer (lämna tomt för löpnummer)",
    "downloadFileAction": "Skriv ut",
    "editCaseTemplateConfirmMessage": "OBS! Du kommer nu redigera mallärendet. Om du vill starta ett ärende från mallärendet välj istället \"Använd\"",
    "editCaseTemplateOkAction": "Redigera mallen",
    "editCaseTemplateConfirmTitle": "Mallärende"
  },
  "listContractCasesPage": {
    "templateNameColumn": "Ärende",
    "contractNumberColumn": "Kontraktsnummer",
    "tenantColumn": "Hyresgäst",
    "propertyDescriptionColumn": "Fastighetsbeteckning",
    "createdByColumn": "Skapat av",
    "updatedColumn": "Ändrat",
    "statusColumn": "Status",
    "signedColumn": "E-signerat",
    "secrecyColumn": "Sekretess",
    "signingUpdatedDateColumn": "Senaste händelse",
    "myFinishedCases": "Mina avslutade",
    "allFinishedCases": "Alla avslutade",
    "myCasesWithLength": "Mina ärenden ({count})",
    "allCasesWithLength": "Alla ärenden ({count})",
    "myFinishedCasesWithLength": "Mina avslutade ({count})",
    "allFinishedCasesWithLength": "Alla avslutade ({count})",
    "signingCases": "E-signeringar",
    "signingCasesWithLength": "E-signeringar ({count})",
    "myCases": "Mina ärenden",
    "allCases": "Alla ärenden",
    "tableNoItems": "Inga ärenden",
    "tableNoMatches": "Inga ärenden matchar sökningen",
    "searchPlaceholderText": "Sök ärende...",
    "activeCases": "Pågående ärenden",
    "finishedCases": "Färdigställda ärenden",
    "newCase": "Nytt ärende",
    "deletCase": "Radera ärende?",
    "deleteInfo": "Är du säker på att du vill radera ärendet? Ett raderat ärende går inte att återskapa.",
    "deleteErrorAlert": "Försök igen",
    "deleteErrorAlertTitle": "Borttagning misslyckades",
    "deleteSuccessAlert": "Ärendet är borttaget",
    "copyErrorAlert": "Ärendet kan inte kopieras",
    "copyErrorAlertTitle": "Kopiering misslyckades",
    "copySuccessAlert": "Ärende kopierat",
    "errorCreatingCaseTemplate": "Något gick fel. Kunde inte skapa mallärendet.",
    "caseTemplateCreated": "Mallärende skapat",
    "editItemAction": "Öppna",
    "shareItemAction": "Dela",
    "setupSigningAction": "Starta e-signering",
    "manageSigningAction": "Hantera e-signering",
    "viewSigningAction": "Visa e-signering",
    "copyItemAction": "Kopiera",
    "caseTemplateItemAction": "Spara som mall",
    "moveToOtherOrganizationAction": "Flytta till...",
    "deleteItemAction": "Radera",
    "actionGotoCaseTemplates": "Från mallärende",
    "actionNewCase": "Från dokument",
    "revertToDoneItemAction": "Ångra Avslutat",
    "copyPreCheckTitle": "Kopiera ärende",
    "copyPreCheckMessage": "Ärendet innehåller äldre versioner av formulär. Följande formulär kommer att uppdateras till senaste versionen när du kopierar ärendet. Du bör kontrollera innehållet efter kopieringen eftersom ändringar i formulären har skett.",
    "removedEntityFields": "Borttagna fält:"
  },
  "templateCaseInfoModal": {
    "upgradeCaseTemplate": "Uppdatera mallärendet",
    "permanentUpgradeInfo": "Du kan också välja att permanent uppdatera mallärendet för att bli av med denna varning.",
    "updatedTemplates": "Uppdaterade formulär:",
    "upgradeError": "Något gick fel. Försök igen eller kontakta supporten om felet kvarstår.",
    "confirmUpgradeTitle": "Uppdatera mallärende",
    "confirmUpgradeMessage": "Samtliga dokument i detta mallärende kommer att uppdateras till senaste mallversionen. Detta går inte att ångra.",
    "confirmUpgradeAction": "Uppdatera"
  },
  "baseTablePagination": {
    "showing": "Visar",
    "to": "till",
    "of": "av",
    "results": "resultat"
  },
  "baseTable": {
    "error": "Något gick fel vid hämtning",
    "toolTipDuplicate": "Kopiera",
    "toolTipEdit": "Redigera",
    "toolTipDelete": "Radera",
    "toolTipShare": "Dela ärende",
    "toolTipNewVersion": "Ny version",
    "toolTipGoto": "Gå till"
  },
  "templatePropertyEditorPageNumberIndicator": {
    "pageNumberIndicatorControl": "Sidnumrering",
    "text": "Text"
  },
  "templatePropertyEditorAppendixNumber": {
    "appendixNumberControl": "Bilagenumrering"
  },
  "templatePropertyEditorText": {
    "textControl": "Text",
    "text": "Text: "
  },
  "templatePropertyEditorLayout": {
    "layout": "Layout",
    "direction": "Huvudaxel:",
    "column": "Vertikal",
    "row": "Horisontell",
    "justifyContent": "Justera huvudaxel",
    "alignItems": "Justera korsaxel",
    "start": "Start",
    "end": "Slut",
    "center": "Mitt",
    "spaceAround": "Utrymme runt",
    "spaceBetween": "Utrymme mellan",
    "spaceEvenly": "Utrymme jämnt",
    "stretch": "Stretch"
  },
  "templatePropertyEditorInput": {
    "placeholder": "Platshållare:",
    "label": "Etikett:",
    "inputControl": "Inmatningsfält",
    "controlAppendixName": "Styr namn på bilaga",
    "defaultValue": "Standardvärde:",
    "defaultValueDropdownError": "Ogiltigt val"
  },
  "templatePropertyEditorCustomText": {
    "customTextControl": "Fritextkontroll",
    "allowRichText": "Tillåt formatering"
  },
  "templatePropertyEditorCheckbox": {
    "title": "Checkbox"
  },
  "templatePropertyEditorCheckboxGroup": {
    "title": "Checkboxgrupp",
    "exclusiveTitle": "Exkluderande"
  },
  "templatePropertyEditorDropdown": {
    "label": "Etikett:",
    "dropdownControl": "Rullgardinsmeny",
    "itemLabel": "Etikett för val {index}:",
    "addOption": "Lägg till val",
    "removeOption": "Ta bort"
  },
  "templateEditorDimensionFields": {
    "heightLabel": "Höjd:",
    "widthLabel": "Bredd:",
    "growLabel": "Väx:"
  },
  "templateEditorIdField": {
    "label": "Id:",
    "duplicateError": "Fält med detta id finns redan."
  },
  "templatePropertyAppendixReference": {
    "heading": "Bilagereferens"
  },
  "templateEditor": {
    "customTextSectionTitle": "Fritext",
    "save": "Spara",
    "addSection": "Lägg till sektion",
    "addCustomTextSection": "Lägg till fritextsektion",
    "addHeaderSection": "Lägg till huvudsektion",
    "addFooterSection": "Lägg till fotsektion",
    "clipboardNode": "Klippbordet",
    "layoutNode": "Layout",
    "textNode": "Text",
    "inputNode": "Inmatningsfält",
    "appendixReference": "Bilagereferens",
    "checkboxGroupNode": "Checkboxgrupp",
    "checkboxNode": "Checkbox",
    "checkmarkNode": "Checkmark",
    "checkboxNodeItemPlaceholder": "Välj etikett",
    "dropdownNode": "Rullgardinsmeny",
    "dropdownNodeItemPlaceholder": "Välj etikett",
    "hoverPositionBefore": "Placera före ",
    "hoverPositionInside": "Placera inuti ",
    "hoverPositionAfter": "Placera efter ",
    "pageNumberIndicator": "Sidnumrering",
    "appendixNumber": "Bilagenummer",
    "closeEditor": "Stäng editor",
    "version": "version:",
    "published": "publicerad",
    "unpublished": "opublicerad"
  },
  "templateNode": {
    "appendixReferenceLabel": "Se bilaga:"
  },
  "templateVersionList": {
    "tableTitle": "Mallversioner",
    "notItemsText": "Inga versioner",
    "noMatchesText": "Inga versioner matchade sökningen",
    "searchPlaceholderText": "Sök versioner...",
    "version": "Version",
    "active": "Aktiv",
    "action": "Åtgärd",
    "preview": "Förhandsgranska",
    "publish": "Publicera",
    "unpublish": "Avpublicera",
    "downloadPDF": "PDF",
    "previewTemplate": "Mall",
    "comment": "Kommentar",
    "createdBy": "Skapad av",
    "createdAt": "Skapad datum",
    "publishedBy": "Publicerad av",
    "publishedAt": "Publicerad datum"
  },
  "invitationsPage": {
    "sendInvitationTitle": "Skicka inbjudan",
    "instructions": "Skickar ett mail med en länk som låter mottagaren ansluta till din organisation med ett nytt eller befintligt användarkonto.",
    "emailPlaceholder": "Email-adress",
    "send": "Skicka",
    "sending": "Skickar...",
    "sent": "Skickad",
    "sentAt": "Skickad",
    "invitationSent": "En inbjudan har skickats till {email}",
    "invalidEmail": "Felaktig email-adress",
    "error": "Något gick fel. Kanske är personen redan inbjuden till organisationen?",
    "openInvitations": "Skickade inbjudningar som ej besvarats ännu",
    "openInvitationsInstructions": "Om du vill påminna eller skicka inbjudan igen behöver du återkalla den och därefter bjuda in på nytt.",
    "cancel": "Återkalla inbjudan",
    "cancelling": "Tar bort...",
    "noInvitations": "Det finns inga obesvarade inbjudningar",
    "noInvitationsInstructions": "Bjud in en kollega till organistaionen genom att fylla i dennes e-postadress i fältet ovan och tryck skicka.",
    "invitationFetchError": "Hämtningen av inbjudningar misslyckades"
  },
  "invitationPage": {
    "wrongEmail": "Du har öppnat en inbjudan till en annan användare, vill du byta inloggning?",
    "invalidToken": "Länken du följt fungerar inte. Om du har fått en inbjudan kan den ha dragits tillbaka. Kontakta organisationen du blivit inbjuden till vid frågor."
  },
  "organizationUserListPage": {
    "tableTitle": "Användare",
    "notItemsText": "Inga användare",
    "noMatchesText": "Inga användare matchar sökningen",
    "searchPlaceholderText": "Sök användare...",
    "firstNameLabel": "Förnamn",
    "lastNameLabel": "Efternamn",
    "emailLabel": "Email",
    "roleLabel": "Roll",
    "confirmDeleteTitle": "Ta bort användare ur organisation",
    "confirmDeleteMessage": "Är du säker på att du vill ta bort användaren ur organisationen?",
    "cantDeleteTitle": "Kan ej ta bort",
    "onlyOwnerInRoleMessage": "Det måste finnas åtminstone en ägare i organisationen.",
    "onlyAdminInRoleMessage": "Det måste finnas åtminstone en administratör i organisationen.",
    "deletingSelfMessage": "Du kan inte ta bort dig själv från organisationen.",
    "errorDeleting": "Det gick inte att ta bort användaren.",
    "ok": "Ok",
    "addAdmin": "Lägg till användare"
  },
  "organizationPage": {
    "header": "Organisationshantering",
    "userNavLink": "Användare",
    "invitesNavLink": "Inbjudningar",
    "credentialsNavLink": "Inställningar",
    "deleteOrganizationNavLink": "Radera organisation"
  },
  "accountPage": {
    "header": "Mina uppgifter",
    "credentialsNavLink": "Uppgifter",
    "changePasswordNavLink": "Byt lösenord",
    "changeEmailNavLink": "Byt email",
    "deleteAccountNavLink": "Radera användarkonto"
  },
  "accountDetailsPage": {
    "changeAccountDetails": "Ändra kontouppgifter",
    "firstNameLabel": "Förnamn*",
    "lastNameLabel": "Efternamn*",
    "firstNameMissingMessage": "Ange förnamn",
    "lastNameMissingMessage": "Ange efternamn",
    "save": "Spara",
    "saving": "Sparar...",
    "saveSuccess": "Kontouppgifter uppdaterade",
    "saveFailed": "Sparningen misslyckades"
  },
  "changePasswordPage": {
    "changePassword": "Byt lösenord",
    "newPassword": "Nytt lösenord*",
    "newPasswordRepeat": "Nytt lösenord (repetera)*",
    "existingPassword": "Nuvarande lösenord*",
    "newPasswordMissingMessage": "Ange nytt lösenord",
    "passwordMismatchMessage": "Lösenord matchar inte",
    "currentPasswordMissingMessage": "Ange nuvarande lösenord",
    "save": "Spara",
    "saving": "Sparar...",
    "saveSuccess": "Lösenord uppdaterat",
    "saveFailed": "Sparningen misslyckades"
  },
  "changeEmailPage": {
    "changeEmail": "Byt email",
    "password": "Lösenord*",
    "emailMismatchMessage": "Email matchar inte",
    "newEmail": "Ny emailaddress*",
    "newEmailRepeat": "Ny email (repetera)*",
    "newEmaildMissingMessage": "Ange ny email",
    "save": "Spara",
    "saving": "Sparar...",
    "emailSentSuccess": "Bekräftelsemail skickat till din nya email",
    "saveFailed": "Sparningen misslyckades",
    "currentPasswordMissingMessage": "Ange nuvarande lösenord",
    "existingPassword": "Nuvarande lösenord*"
  },
  "deleteAccountPage": {
    "deleteAccount": "Radera användarkonto",
    "deleteAccountInformation": "För att radera ditt konto måste du ange ditt nuvarande lösenord",
    "deleteAccountInformationConfirm": "Om du väljer att radera ditt konto kommer all personlig data relaterad till ditt konto permanent raderas och går ej att återställa. Är du säker på att du vill radera kontot?",
    "currentPassword": "Nuvarande lösenord*",
    "currentPasswordMissingMessage": "Ange nuvarande lösenord",
    "deletingAccount": "Raderar konto...",
    "success": "Ditt konto är nu raderat",
    "failed": "Kunde ej radera användarkontot. Om problemet kvarstår kontakta supporten."
  },
  "manageOrganization": {
    "deleteOrganization": "Radera organisation",
    "deleteOrganizationInformation": "Om du väljer att radera organisationen kommer all data relaterad till organisationen permanent raderas och går ej att återställa. För att radera organisationen måste du ange ditt lösenord och bekräfta organisationens namn. ",
    "deleteOrganizationInformationConfirm": "Om du väljer att radera organisationen kommer all data relaterad till organisationen permanent raderas och går ej att återställa. Är du säker på att du vill radera organisationen?",
    "currentOrganizationName": "Organisationens namn*",
    "currentOrganizationError": "Felaktigt namn",
    "currentPassword": "Ditt lösenord*",
    "currentPasswordMissingMessage": "Ange ditt lösenord",
    "deletingOrganization": "Raderar organisation...",
    "success": "Organisationen är nu raderad",
    "knownError": "Kunde ej radera organisationen. Kontrollera ditt lösenord och organisationens namn.",
    "unknownError": "Kunde ej radera organisationen. Försök igen och kontakta supporten om felet kvarstår.",
    "onlyOwnerCanDeleteText": "Endast den användare som har rollen Organisationsägare kan radera organisationen. "
  },
  "customerPage": {
    "header": "Kundadministration",
    "organizationNavLink": "Organisationer",
    "credentialsNavLink": "Kunduppgifter",
    "apiKeysNavLink": "Api-nycklar",
    "subscriptionNavLink": "Abonnemang",
    "adminsNavLink": "Administratörer",
    "commentsNavLink": "Anteckningar",
    "templateStatsNavLink": "Användning",
    "deleteCustomerNavLink": "Radera kund"
  },
  "manageCustomer": {
    "blockCustomerHeading": "Inaktivera och aktivera kund",
    "blockCustomerInformation": "Kunden kommer inaktiveras och kommer inte kunna skarpmarkera eller e-signera kontraktsärenden. Vill du fortsätta?",
    "blockedCustomerInformation": "Kunden är inaktiverad och kan inte skarpmarkera eller e-signera kontraktsärenden",
    "activateCustomerInformation": "Kunden kommer aktiveras. Vill du fortsätta?",
    "activeCustomerInformation": "Kunden är aktiverad",
    "blockCustomer": "Inaktivera kund",
    "blockingCustomer": "Inaktiverar kund",
    "blockCustomerError": "Kunde inte blockera kunden. Försök igen och kontakta supporten om felet kvarstår.",
    "activateCustomer": "Aktivera kund",
    "activatingCustomer": "Aktiverar kund",
    "activateCustomerError": "Kunde inte aktivera kunden. Försök igen och kontakta supporten om felet kvarstår.",
    "deleteCustomer": "Radera kund",
    "deleteCustomerInformation": "Om du väljer att radera kunden kommer all data relaterad till kunden, inklusive alla organisationer permanent raderas och går ej att återställa. För att radera kunden måste du ange ditt lösenord och bekräfta kundens namn. ",
    "deleteCustomerInformationConfirm": "Om du väljer att radera kunden kommer all data relaterad till kunden, inklusive alla organisationer permanent raderas och går ej att återställa. Är du säker på att du vill radera kunden?",
    "currentCustomerName": "Kundens namn*",
    "currentCustomerError": "Felaktigt namn",
    "currentPassword": "Ditt lösenord*",
    "currentPasswordMissingMessage": "Ange ditt lösenord",
    "deletingCustomer": "Raderar kund...",
    "success": "Kunden är nu raderad",
    "knownError": "Kunde ej radera kunden. Kontrollera ditt lösenord och kundens namn.",
    "unknownError": "Kunde ej radera kunden. Försök igen och kontakta supporten om felet kvarstår.",
    "onlyOwnerCanDeleteText": "Endast den användare som har rollen Kundägare kan radera kunden.",
    "knownErrorActiveSubscription": "Du kan inte radera en kund som har ett aktivt abonnemang",
    "activeSubscriptionCantDelete": "Du kan inte radera en kund som har ett aktivt abonnemang"
  },
  "sadminComments": {
    "customerCommentHeading": "Kundanteckningar",
    "addNewComment": "Lägg till ny",
    "editComment": "Redigera anteckning",
    "deleteComment": "Radera anteckning",
    "deleteCommentConfirmationText": "Är du säker på att du vill radera anteckningen?",
    "lastUpdatedLabel": "Senast ändrad",
    "commentDialogAddHeading": "Lägg till anteckning",
    "commentDialogEditHeading": "Redigera anteckning",
    "commentDialogPlaceholderText": "Skriv din anteckning här...",
    "saveSuccess": "Anteckningen sparad",
    "saveFailed": "Anteckningen kunde inte sparas",
    "updateSuccess": "Anteckningen uppdaterades",
    "updateFailed": "Anteckningen kunde inte uppdateras",
    "deleteSuccess": "Anteckningen raderades",
    "deleteFailed": "Anteckningen kunde inte raderas",
    "noComments": "Inga anteckningar tillagda"
  },
  "customerDetailsPage": {
    "changeCustomerDetails": "Ändra kunduppgifter",
    "changeCustomerAddress": "Ändra kundadress",
    "changeInvoiceAddress": "Ändra faktureringsadress",
    "nameLabel": "Kundnamn*",
    "invoiceReferenceLabel": "Fakturareferens",
    "addressCityLabel": "Postort*",
    "addressCountryLabel": "Land (Om annat än Sverige)",
    "addressStreetLabel": "Adress*",
    "addressZipCodeLabel": "Postnummer*",
    "invoiceAddressCityLabel": "Postort*",
    "invoiceAddressCountryLabel": "Land (Om annat än Sverige)",
    "invoiceAddressStreetLabel": "Adress*",
    "invoiceAddressZipCodeLabel": "Postnummer*",
    "organizationNumberLabel": "Organisationsnummer*",
    "phoneNumberLabel": "Telefonnummer",
    "emailLabel": "E-postadress för faktura",
    "membershipLabel": "Medlem",
    "membershipNumberLabel": "Medlemsnummer",
    "chooseInvoiceAddress": "Ange faktureringsadress om annan än ovan",
    "nameMissingMessage": "Du måste ange ett namn.",
    "organizationNumberMissingMessage": "Du måste ange ett organisationsnummer.",
    "organizationNumberInvalidMessage": "Ska anges i formatet 123456-7890",
    "addressMissingMessage": "Du måste ange en adress.",
    "zipCodeMissingMessage": "Du måste ange ett postnummer.",
    "cityMissingMessage": "Du måste ange en postort.",
    "save": "Spara",
    "saving": "Sparar...",
    "saveSuccess": "Kunduppgifter uppdaterade",
    "saveFailed": "Sparningen misslyckades",
    "customerIdLabel": "Kund-id",
    "blockedLabel": "Blockerad",
    "invoiceEmailInvalidMessage": "Ogiltig e-postadress",
    "invoiceReferenceMissingMessage": "Du måste ange fakturareferens",
    "customerCreatedLabel": "Skapad",
    "saveFailedBlocked": "Du kan inte uppdatera den här kunden. Kontakta supporten för mer information.",
    "saveFailedBlockedOrgNum": "Du kan inte använda angivet organisationsnummer. Kontakta supporten för mer information."
  },
  "customerOrganizationListPage": {
    "createOrganization": "Lägg till organisation",
    "organizations": "Organisationer",
    "organizationName": "Organisationsnamn",
    "organizationId": "Organisations-id",
    "numberOfCases": "Antal ärenden",
    "noItemsText": "Inga organisationer tillgängliga",
    "noAccessMessageTitle": "Du saknar behörighet",
    "noAccessMessageText": "Du är inte administratör för organisationen och kan därför inte redigera den.  "
  },
  "customerApiKeysPage": {
    "tableTitle": "API-nycklar",
    "notItemsText": "Inga API-nycklar tillgängliga",
    "noMatchesText": "Det finns inget resultat som matchar din sökning",
    "searchPlaceholderText": "Sök bland API-nycklar",
    "firstColumnLabel": "Beskrivning",
    "secondColumnLabel": "Skapad",
    "createKey": "Skapa nyckel",
    "apiUrl": "API-sökväg:"
  },
  "createApiKeyModal": {
    "infoText": "API-nycklar används för att autentisera med vårat REST-api. En API-nyckel bör hanteras som känslig information likt ett lösenord.",
    "descriptionMissing": "Ange en beskrivning",
    "creating": "Skapar...",
    "close": "Stäng",
    "create": "Skapa",
    "description": "Beskrivning",
    "descriptionHint": "En beskrivning på syftet med API-nyckeln underlättar vid borttagning för att urskilja API-nycklarna från varandra.",
    "title": "Skapa API-nyckel",
    "key": "API-nyckel",
    "keyHint": "API-nyckeln visas inte igen efter att denna dialogen stängs ner. Spara den på ett säkert ställe och skapa en ny om den försvinner.",
    "keyCreated": "Din nya API-nyckel är:"
  },
  "customerAdminsPage": {
    "addAdmin": "Lägg till kundadministratör",
    "tableTitle": "Kundadministratörer",
    "noItemsText": "Inga kundadministratörer tillgängliga",
    "columnName": "Namn",
    "columnEmail": "E-post",
    "confirmRemoveTitle": "Ta bort kundadministratör",
    "confirmRemoveMessage": "Är du säker på att du vill ta bort den här användaren som kundadministratör?",
    "cantRemoveTitle": "Kan ej ta bort",
    "cantRemoveSelf": "Du kan inte ta bort dig själv som kundadministratör",
    "columnRole": "Roll",
    "cantRemoveOwner": "Du kan inte ta bort primär kontaktperson utan att först välja en ny",
    "roleAdmin": "Administratör",
    "roleOwner": "Primär kontaktperson",
    "addCustomerAdminAction": "Lägg till kundadministratör"
  },
  "baseInput": {
    "copySuccess": "Värdet kopierat till klippbordet",
    "charactersLeft": "tecken kvar"
  },
  "editUser": {
    "editRole": "Ändra roll för",
    "informationText": "Administratörer kan ändra organisationsinställningar, bjuda in och ta bort användare från organisationen samt göra andra användare till administratörer. Organisationsägare kan utöver detta också ändra organisationsägare samt radera organisationen från tjänsten.",
    "role": "Roll",
    "atLeastOneOwner": "Du kan inte byta roll på den här användaren eftersom det måste finnas åtminstone en organisationsägare. Vill du byta roll gör först någon annan användare till organisationsägare.",
    "atLeastOneAdmin": "Endast Organisationsägare kan byta roll på den här användaren eftersom det är den sista användaren med administratörsbehörighet.",
    "onlyOwners": "Bara användare som är organisationsägare kan ändra organisationsägare",
    "save": "Spara",
    "saving": "Sparar...",
    "cancel": "Avbryt"
  },
  "fileUpload": {
    "uploadFailed": "Uppladdningen misslyckades",
    "cancel": "Avbryt",
    "upload": "Ladda upp",
    "selectFile": "Välj fil..",
    "uploadLogo": "Ladda upp logga",
    "saving": "Sparar"
  },
  "organizationDetailsPage": {
    "organization": "Organisation",
    "organizationNumber": "Organisationsnummer",
    "phoneNumber": "Telefonnummer",
    "mail": "Email",
    "save": "Spara",
    "cancel": "Avbryt",
    "street": "Gatuadress",
    "city": "Postort",
    "zipCode": "Postnummer",
    "publicAsDefaultInfoText": "Standardinställningen för en organisation är att kontraktsärenden är av typen sekretess när de skapas. Det innebär att bara användaren som skapat ärendet har tillgång till det. Användaren kan sedan dela ärendet med specifika användare. Nedan kan du ändra så att alla ärenden som standard istället är tillgängliga för alla i organisationen. Notera att administratörsanvändare alltid kan se alla ärenden.",
    "publicAsDefault": "Ärenden som skapas delas med alla i organisationen",
    "update": "Uppdatera",
    "logoText": "Här kan du ladda upp din organisations logga. Uppladdad logga kommer ersätta Fastighetsägarnas logga i dokumenten och finnas med på alla kontraktsärenden som skapas i organisationen.",
    "upload": "Ladda upp",
    "saveFailed": "Sparningen misslyckades",
    "saveSuccess": "Sparningen lyckades",
    "logo": "Logga",
    "maxSizeText": "Maxstorleken för en logga är 2 MB.",
    "allowedText": "Tillåtna filtyper är JPG, JPEG, PNG och SVG.",
    "recomendedText": "Rekommenderad storlek 170x54 px.",
    "saving": "Sparar",
    "goToCustomerPageText": "Kund & abonnemangsinställningar",
    "removeLogo": "Ta bort logga",
    "removeLogoFail": "Något gick fel. Var god försök igen.",
    "removeLogoSuccess": "Logga borttagen",
    "redactionTitle": "Gallring",
    "redactionInfoText": "Tiden räknas från det att ett ärende senast uppdaterades eller på annat sätt ändrades. Ärenden ändrar automatiskt status från SKARP till AVSLUTAD efter 12 månader och ärenden med status UTKAST raderas efter 12 månaders inaktivitet.",
    "redactDraftsLabel": "Radera ärenden med status UTKAST efter",
    "redactConcludedLabel": "Radera ärenden med status AVSLUTAD efter",
    "redact1Month": "1 månad",
    "redact3Month": "3 månader",
    "redact6Month": "6 månader",
    "redact12Month": "1 år",
    "redact24Month": "2 år",
    "redact36Month": "3 år",
    "redact48Month": "4 år",
    "setLogoSuccess": "Logga uppdaterad"
  },
  "organizationDetailsTab": {
    "save": "Spara",
    "cancel": "Avbryt",
    "saveFailed": "Sparningen misslyckades",
    "saveSuccess": "Sparningen lyckades",
    "saving": "Sparar",
    "changeOrganizationDetails": "Ändra organisationsuppgifter",
    "organizationIdLabel": "Organisations-id",
    "organizationName": "Organisationsnamn",
    "nameMissingMessage": "Du måste ange ett namn.",
    "customerNameLabel": "Tillhör kund",
    "numberOfCasesLabel": "Antal ärenden",
    "goToCustomerAdmin": "Kundadministration",
    "moveOrganization": "Flytta till annan kund",
    "createdLabel": "Skapad",
    "moveOrganizationInformation": "Flytta ägarskapet av hela organisationen och dess innehåll den en annan kund"
  },
  "templatePropertyEditorAppendixReference": {
    "filter": "Filtrera",
    "relatedAppendice": "Relaterade bilagor",
    "recommendedAppendices": "Rekommenderade bilagor",
    "filterPlaceholder": "filtrera på mallnamn"
  },
  "contractNodeAppendixReference": {
    "label": "Se bilaga:",
    "noOption": "Rensa valda",
    "error": "Något gick fel med att välja bilaga. Vänligen försök igen",
    "adding": "Lägger till...",
    "appendicesInCase": "Tillagda till ärendet",
    "availableTemplates": "Lägg till bilaga",
    "recommendedAppendix": "Rekommenderad bilaga",
    "maxSelected": "Det går max att referera till 3 st bilagor"
  },
  "contractCustomAppendixReferenceMenu": {
    "adding": "Lägger till...",
    "noResults": "Inget val",
    "noOptions": "Inga bilagor är valbara för detta ärende",
    "error": "Något gick fel med att välja bilaga. Vänligen försök igen",
    "appendicesPlaceholder": "Lägg till bilaga",
    "uploadFile": "Ladda upp eget dokument eller bild",
    "addPlaceholder": "Bilaga som ska läggas till senare",
    "tooltip": "Gör utrymme för en bilaga som ska läggas till efter utskrift. Bilagenumreringen kommer ta hänsyn till bilagan automatiskt.",
    "noAppendingInAnonymousMode": "För att lägga till bilagor måste du vara inloggad",
    "fileTooLarge": "En uppladdad fil får inte vara större än 5MB",
    "fileConversionFailed": "Kunde inte konvertera filen till PDF",
    "includeToc": "Inkludera bilageförteckning",
    "includeTocMaxLimit": "För många bilagor för bilageförteckning"
  },
  "templatePropertyEditorSection": {
    "title": "Sektion",
    "titleLabel": "Titel",
    "alignRight": "Högerjustera text",
    "bottomBorder": "Kant nedtill",
    "helpText": "Hjälptext",
    "hideTitle": "Göm titel"
  },
  "templateEditorLabelField": {
    "label": "Etikett:"
  },
  "entityField": {
    "entity": "Entitet",
    "entityName": "Namn",
    "select": "Välj"
  },
  "warningBanner": {
    "textLong": "Varning! Versionen du editerar har kopplade ärenden. Eventuella förändringar kommer påverka alla pågående ärenden och går inte att ångra",
    "textShort": "Varning! Versionen du editerar har kopplade ärenden",
    "unsavedChanges": "Du har osparade ändringar"
  },
  "createTemplateFromVersion": {
    "createNewTemplate": "Skapa ny mall",
    "save": "Spara",
    "saving": "Sparar...",
    "fail": "Något gick fel, vänligen försök igen",
    "templateName": "Mallnamn",
    "referenceNumber": "Referensnummer",
    "price": "Pris",
    "description": "Beskrivning",
    "categorySelectPlaceholder": "Välj kategorier",
    "categories": "Kategorier",
    "success": "Ny mall skapad"
  },
  "templatePropertyEditorFooter": {
    "text": "Text",
    "footer": "Footer"
  },
  "internal": {
    "contractCases": {
      "editContractCase": {
        "fetchingCase": "Hämtar ärende...",
        "fetchErrorMessage": "Något gick fel vid hämtning av ärende"
      }
    }
  },
  "external": {
    "contractCases": {
      "editContractCase": {
        "fetchingCase": "Hämtar ärende...",
        "fetchErrorMessage": "Något gick fel vid hämtning av ärende"
      }
    }
  },
  "tree": {
    "moving": "Flyttar {title}"
  },
  "templateEditorBorderFields": {
    "label": "Kanter",
    "top": "Uppe",
    "right": "Höger",
    "bottom": "Nere",
    "left": "Vänster"
  },
  "templateEditorMarginFields": {
    "label": "Marginaler",
    "top": "Uppe:",
    "right": "Höger:",
    "bottom": "Nere:",
    "left": "Vänster:"
  },
  "addCustomerAdminModal": {
    "title": "Lägg till kundadministratör",
    "subTitle": "Välj användare",
    "informationText": "Kundadministratörer kan se och ändra faktureringsuppgifter, hantera abonnemangsinställningar och API-nycklar samt lägga till och ta bort organisationer knutna till kunden.",
    "usersPlaceholder": "Välj användare att göra till administratör",
    "noResults": "Inga användare",
    "save": "Lägg till",
    "cancel": "Avbryt",
    "saving": "Lägger till..."
  },
  "editCustomerAdminModal": {
    "title": "Ändra kundadministratör",
    "informationText": "En kund måste alltid ha en primär kontaktperson. Den primära kontaktpersonen kan inte tas bort.",
    "makeOwner": "Gör till primär kontaktperson",
    "saving": "Ändrar..."
  },
  "shareCaseModal": {
    "title": "Dela ärende",
    "subTitle": "Välj användare som ska kunna se och ändra ärendet",
    "informationText": "Om ärendet är publikt kan alla i organisationen se och ändra ärendet. Om ärendet inte är publikt kan bara de användare som ärendet är delat med se och ändra ärendet. Notera att administratörsanvändare alltid kan se och ändra alla ärenden.",
    "usersPlaceholder": "Välj användare att dela med",
    "confirmShareTitle": "Är du säker?",
    "confirmRemovingSelf": "Sparar du nu kommer du inte ha åtkomst till ärendet längre.",
    "confirmNoUsers": "Ärendet är inte publikt och kommer inte vara delat med några användare. Sparar du nu kommer endast administratörsanvändare ha åtkomst till ärendet.",
    "noResults": "Inga resultat",
    "public": "Publikt",
    "save": "Spara",
    "cancel": "Avbryt",
    "saving": "Sparar..."
  },
  "moveCaseToOtherOrganizationModal": {
    "title": "Flytta ärende till annan organisation",
    "subTitle": "Välj vilken organisation ärendet ska flyttas till",
    "informationText": "Du kan flytta detta ärende till en annan organisation tillhörande samma kundkonto. Om användaren som startade ärendet inte är medlem i den nya organisationen, kommer ärendet hamna i listan av dina ärenden.",
    "noDestinationsText": "Du kan inte flytta detta ärende eftersom det inte finns några andra organisationer inom detta kundkonto.",
    "caseName": "Ärende",
    "contractNumber": "Kontraktsnummer",
    "destinationOrganization": "Destination",
    "organizationPlaceholder": "Välj organisation",
    "noResults": "Inga organisationer",
    "confirmMoveTitle": "Är du säker på att du vill flytta ärendet?",
    "move": "Flytta",
    "cancel": "Avbryt",
    "moving": "Flyttar..."
  },
  "setupCaseSigningModal": {
    "title": "E-signera ärende",
    "addSignatoryAction": "Lägg till part",
    "addSignatoryTitle": "Lägg till part",
    "confirmRemoveSignatoryMessage": "Är du säker på att du vill ta bort den valda parten?",
    "confirmRemoveSignatoryTitle": "Ta bort part",
    "confirmStartSigningTitle": "Starta e-signering",
    "confirmStartSigningMessage": "Ärendet kommer nu skickas för e-signering till de valda parterna. Vill du fortsätta?",
    "editSignatoryTitle": "Redigera part",
    "informationText1Verified": "Ärendet skickas för e-signering till den externa tjänsten Verified. När ärendet är e-signerat kommer det automatiskt att avslutas. Den e-signerade handlingen behöver laddas ned. Länken för nedladdning fungerar endast under viss tid och Fastighetsägarna Dokument sparar inte den e-signerade handlingen. Villkoren för användning av e-signering framgår av ",
    "informationText1Scrive": "Ärendet skickas för e-signering till den externa tjänsten Scrive. När ärendet är e-signerat kommer det automatiskt att avslutas. Den e-signerade handlingen behöver laddas ned. Länken för nedladdning fungerar endast under viss tid och Fastighetsägarna Dokument sparar inte den e-signerade handlingen. Villkoren för användning av e-signering framgår av ",
    "informationTextTermsAndConditions": "våra allmänna villkor",
    "informationTextReadMoreAboutESigning": "Läs mer om e-signering",
    "informationText2": ".",
    "informationText3": "Användaren ansvarar för att avgöra om ett ärende är lämpligt att e-signera. Vissa avtal kan enligt lag inte e-signeras, det gäller till exempel avtal om överlåtelse av bostadsrätt. För vissa avtal krävs särskild hantering. Överenskommelse om avstående av besittningsskydd (formulär som börjar på HN) måste till exempel e-signeras separat från hyresavtalet.",
    "moveDownAction": "Flytta nedåt",
    "moveUpAction": "Flytta uppåt",
    "removeSignatoryAction": "Ta bort",
    "noItemsText": "Lägg till minst en signerande part för att starta e-signering",
    "sequentialSigning": "Signera ärendet i turordning",
    "hidePersonalNumbers": "Dölj personnummer",
    "hidePersonalNumbersDisclaimer": "Personnummer kommer inte att skrivas ut på verifikatet. Personnummer finns tillgängligt i pdf:ens loggdata i syfte att säkerställa verifikation.",
    "smsDisclaimer": "Signerande part identifieras genom SMS. Signeringen uppfyller därmed inte kraven för avancerad elektronisk signatur enligt EU:s eIDAS-förordning.",
    "signatoryEmail": "E-post",
    "signatoryFirstName": "Förnamn",
    "signatoryLastName": "Efternamn",
    "signatoryPhoneNumber": "Mobilnummer",
    "signatoryRole": "Roll",
    "signatorySigningMethod": "Metod",
    "start": "Starta e-signering",
    "starting": "Startar...",
    "startSigningError": "Det gick inte att starta e-signeringen i signeringstjänsten. Försök igen om en stund.",
    "customerBlockedError": "Kundkontot är inaktiverat och ärenden kan inte e-signeras. Vänligen kontakta Fastighetsägarna dokument support.",
    "fileTooLargeError": "Det här ärendet är för stort för att signeras. Det får inte överstiga 10MB. Kontrollera storleken på uppladdade filer.",
    "tableTitle": "Signerande parter",
    "templateNotAllowedSigning": "Det här ärendet innehåller dokument som inte tillåter e-signering",
    "templateSigningOnlyAsMain": "Det här ärendet innehåller bilagor som endast får signeras självständigt i ett eget ärende. Ta bort bilagan för att e-signera ärendet"
  },
  "caseSignatoryModal": {
    "signatoryRoleLabel": "Roll",
    "signatoryRoleViewer": "Granskare",
    "signatoryRoleSigner": "Signerande part",
    "emailLabel": "E-postadress",
    "firstNameLabel": "Förnamn",
    "lastNameLabel": "Efternamn",
    "phoneNumberLabel": "Mobilnummer",
    "personalNumberLabel": "Personnummer (frivilligt)",
    "signingMethodLabel": "Signeringsmetod",
    "signingMethodBankID": "BankID",
    "signingMethodSMS": "SMS",
    "errorEmailRequired": "E-postadress måste anges",
    "errorEmailInvalid": "Felaktig e-postadress",
    "errorFirstNameRequired": "Förnamn måste anges",
    "errorLastNameRequired": "Efternamn måste anges",
    "errorPhoneNumberRequired": "Mobilnummer måste anges",
    "errorPhoneNumberInvalid": "Ange mobilnummer med landskod och utan inledande nolla (ex. +467xxxxxxxx)",
    "errorEmailAlreadyInUse": "E-postadressen används för en annan part",
    "errorPersonalNumberInvalid": "Ange korrekt personnummer i formatet ÅÅÅÅMMDDXXXX"
  },
  "manageCaseSigningModal": {
    "title": "Hantera e-signering",
    "loadError": "Aj då! Kunde inte kontakta signeringstjänsten. Försök igen om en stund.",
    "confirmCancelSigningTitle": "Återkalla e-signering",
    "confirmCancelSigningMessage": "E-signeringen kommer nu återkallas. Vill du fortsätta?",
    "confirmSendReminderToSignatoryTitle": "Skicka påminnelse",
    "confirmSendReminderToSignatoryMessage": "Den signerande parten kommer få en påminnelse. Vill du fortsätta?",
    "confirmSendRemindersToSignatoriesTitle": "Skicka påminnelser",
    "confirmSendRemindersToSignatoriesMessage": "Parter som ännu inte signerat kommer få en påminnelse. Vill du fortsätta?",
    "editSignatoryTitle": "Redigera part",
    "signatoryOrder": "Ordning",
    "signatoryEmail": "E-post",
    "signatoryFirstName": "Förnamn",
    "signatoryLastName": "Efternamn",
    "signatoryPhoneNumber": "Mobilnummer",
    "signatoryRole": "Roll",
    "signatorySigningMethod": "Metod",
    "signatoryStatus": "Status",
    "cancelSigning": "Återkalla e-signering",
    "cancellingSigning": "Återkallar...",
    "cancelSigningError": "Det gick inte att återkalla e-signeringen. Försök igen om en stund.",
    "cancelledSigning": "E-signeringen har återkallats",
    "sendReminder": "Skicka påminnelse",
    "sendReminders": "Skicka påminnelser",
    "sendingReminder": "Skickar...",
    "sendingReminders": "Skickar...",
    "sentReminder": "En påminnelse har skickats",
    "sentReminders": "Påminnelser har skickats",
    "updateSignatoryError": "Det gick inte att redigera den parten. Försök igen om en stund.",
    "updatingSignatory": "Uppdaterar...",
    "updatedSignatory": "Uppgifterna för parten har uppdaterats",
    "sendReminderError": "Det gick inte att skicka en påminnelse. Försök igen om en stund.",
    "sendRemindersError": "Det gick inte att skicka påminnelser. Försök igen om en stund.",
    "newSigning": "Ny e-signering",
    "tableTitle": "Signerande parter",
    "initiatedInfo": "Initierades {initiatedDate} av {initiatedBy}",
    "referenceIdVerified": "Kuvert-id: {referenceId}",
    "referenceIdScrive": "Dokument-id: {referenceId}"
  },
  "viewCaseSigningModal": {
    "title": "Visa e-signering",
    "signedInfo": "Signerat av alla parter {signedDate}",
    "tableTitle": "Signerande parter",
    "signatoryOrder": "Ordning",
    "signatoryEmail": "E-post",
    "signatoryFirstName": "Förnamn",
    "signatoryLastName": "Efternamn",
    "signatoryPhoneNumber": "Mobilnummer",
    "signatoryRole": "Roll",
    "signatorySigningMethod": "Metod",
    "signatoryStatus": "Status",
    "newSigning": "Ny e-signering",
    "getSignedCasePdf": "Ladda ner signerat dokument",
    "getSignedCasePdfError": "Det gick inte att ladda ner det signerade dokumentet från signeringstjänsten. Försök igen om en stund.",
    "getSignedCasePdfNoLongerAvailable": "Det signerade dokumentet är inte längre tillgängligt för nedladdning från signeringstjänsten."
  },
  "invitesModal": {
    "title": "Väntande inbjudningar",
    "organization": "Organisation",
    "inviteSent": "Inbjudan skickad: ",
    "acceptInvite": "Acceptera inbjudan",
    "declineInvite": "Avvisa",
    "accepting": "Accepterar inbjudan...",
    "declining": "Nekar inbjudan...",
    "inviteAccepted": "Du har accepterat inbjudan till {organizationName} och har nu tillgång till organisationens dokument.",
    "inviteDeclined": "Du har nekat inbjudan från {organizationName}."
  },
  "forgotPassword": {
    "title": "Glömt lösenord?",
    "errorTitle": "Ett fel uppstod",
    "error": "Förfrågan kunde inte hanteras.",
    "emailMissing": "Ange epost",
    "successToast": "Förfrågan skickades",
    "successMessage": "Ett mail har skickats till {email} med instruktioner för att återställa lösenordet.",
    "resetPassword": "Skicka",
    "resettingPassword": "Skickar",
    "emailInvalid": "Ogiltig epost"
  },
  "resetPassword": {
    "title": "Återställ lösenord",
    "successMessage": "Lösenordet är återställt.",
    "login": "Tryck här för att logga in.",
    "errorTitle": "Ett fel uppstod",
    "error": "Förfrågan kunde inte hanteras.",
    "repeatPassword": "Upprepa lösenord",
    "passwordMissing": "Ange epost",
    "passwordLength": "Lösenordet måste vara minst 6 tecken långt",
    "passwordMismatch": "Lösenorden måste vara likadana",
    "resetPassword": "Återställ lösenord",
    "resettingPassword": "Återställer lösenord"
  },
  "notEnoughQuotaModal": {
    "title": "Abonnemanget tillåter inte detta",
    "message": "Ärendet innehåller fler dokument än vad ditt abonnemang tillåter. Vill du uppgradera ditt abonnemang eller betala överskjutande {excess} dokument med kort?",
    "buttonText": "Stäng",
    "titleNoSubscription": "Hur vill du betala?",
    "messageNoSubscription": "För att kunna skarpmarkera ett ärende eller lägga till bilagor i ett redan skarpmarkerat ärende behöver du betala för dokumenten. Du kan välja mellan att teckna ett abonnemang eller betala för de {needed} dokumenten i detta ärende med kort.",
    "actionSubscriptions": "Visa abonnemang",
    "actionCardPayment": "Betala med kort",
    "actionCardPaymentCommingSoon": "Kommer snart...",
    "titleUser": "Betalning",
    "messageUser": "För att skarpmarkera ett ärende eller lägga till bilagor i ett redan skarpmarkerat ärende behöver du betala. Betala direkt med kort eller kontakta er administratör för hjälp med att sätta upp ett abonnemang.",
    "cardPaymentErrorGeneric": "Vi beklagar men ett okänt fel har inträffat. Om du behöver kontakta supporten så ska du ange betalningsreferens {reference}",
    "titleCardPaymenComplete": "Kortbetalning",
    "cardPaymentErrorXXXX": "Aj då, vi kunde inte slutföre betalningen. Var god kontrollera att du angivit rätt kortuppgifter och försök igen. Om du behöver kontakta supporten kan du ange betalningsreferens {reference}",
    "titleChooseSubscription": "Välj abonnemang"
  },
  "adminUsersPage": {
    "title": "Användare",
    "tableNoItems": "Kan inte hitta några användare (något måste vara fel)",
    "tableNoMatches": "Inga användare matchar din sökning",
    "searchPlaceholderText": "Sök användare"
  },
  "userPage": {
    "header": "Användaradministration",
    "detailsNavLink": "Detaljer",
    "orgsNavLink": "Organisationer",
    "tabManageUser": "Hantera"
  },
  "userDetailsPage": {
    "changeUserDetails": "Användaruppgifter",
    "firstNameLabel": "Förnamn*",
    "lastNameLabel": "Efternamn*",
    "emailNameLabel": "Epostadress*",
    "firstNameMissing": "Du måste ange ett förnamn",
    "lastNameMissing": "Du måste ange ett efternamn",
    "emailMissing": "Du måste ange en epostadress",
    "confirmEmail": "Bekräfta epostadress",
    "confirmingEmail": "Bekräftar...",
    "emailNotConfirmedLabel": "Epostadress ej bekräftad",
    "emailConfirmedLabel": "Epostadress bekräftad",
    "emailConfirmingFailed": "Epostadressen kunde inte bekräftas",
    "userIdLabel": "Användar-id",
    "userLastLoginLabel": "Senaste inloggning"
  },
  "adminOrganizationListPage": {
    "role": "Roll"
  },
  "subscriptionPurchaseModal": {
    "titel": "Bekräfta abonnemang",
    "orderSummary": "Sammanfattning",
    "currentSubscription": "Nuvarande abonnemang",
    "selectedSubscription": "Valt abonnemang",
    "selectedSubscriptionPins": "Dokument i valt abonnemang",
    "selectedSubscriptionPrice": "Pris",
    "pricePerYear": "kr per år",
    "memberPrice": "(medlemspris)",
    "invoiceAddress": "Fakturaadress",
    "noInvoiceReferenceText": "Fakturareferens ej angiven",
    "editInvoiceAddressButton": "Ändra fakturaadress",
    "subscriptionUpdated": "Abonnemang uppdaterat",
    "acceptTermsText1": "jag accepterar härmed de",
    "acceptTermsText2": "för tecknande av abonnemang i Fastighetsägarna Dokument.",
    "termsTitle": "allmänna villkoren",
    "confirm": "Bekräfta",
    "subscriptionPurchase": "Du har valt att teckna {name}. Abonnemanget kommer att börja gälla omedelbart efter bekräftelse.",
    "subscriptionUpgrade": "Du har valt att uppgradera till {name}. Det nya abonnemanget kommer att börja gälla omedelbart efter bekräftelse.",
    "subscriptionDowngrade": "Du har valt att nedgradera till {name}. Det nya abonnemanget kommer att börja gälla {renewal} när ditt befintliga abonnemang löper ut."
  },
  "editCustomerQuotaModal": {
    "titel": "Ändra saldo",
    "save": "Spara",
    "saving": "Sparar...",
    "cancel": "Avbryt"
  },
  "editCustomerSubscriptionModal": {
    "titel": "Ändra abonnemangsuppgifter",
    "noSubscription": "Inget abonnemang",
    "startDate": "Gäller fr.o.m.*",
    "renewalDate": "Nästa förnyelse*",
    "useCustomPlan": "Använd anpassat abonnemang",
    "price": "Pris (kr/år)*",
    "pins": "Pinnar*",
    "customSubscriptionName": "Namn (syns i fakturaunderlag)*",
    "subscriptionPlan": "Abonnemang",
    "startDateInfoToday": "Abonnemanget kommer att börja gälla omedelbart. Nuvarande pinnar <b>kommer att behållas och adderas</b> med abonnemangets antal.",
    "startDateInfoFuture": "Abonnemanget kommer att börja gälla vid angivet datum. Antalet pinnar kommer då att sättas till de antal som abonnemanget anger.",
    "startDateCancellationInfoToday": "Abonnemanget kommer att tas bort omedelbart. Eventuella pinnar kvarstår.",
    "startDateCancellationInfoFuture": "Abonnemanget kommer att tas bort vid angivet datum. Eventuellt pinnar kvarstår.",
    "cancelActiveSubscription": "Avsluta abonnemang",
    "cancellationSuccess": "Abonnemanget avslutat",
    "confirmCancelTitle": "Avsluta abonnemang",
    "confirmCancelMessage": "Är du säker på att du vill avsluta abonnemanget? Abonnemanget kommer att löpa på till slutdatum men därefter inte förnyas.",
    "unlimitedPins": "Obegränsat antal dokument",
    "setUnlimitedPins": "Sätt obegränsat",
    "removeUnlimitedPins": "Ändra"
  },
  "customerSubscriptionTab": {
    "editQuotaButton": "Ändra saldo",
    "editSubscriptionButton": "Ändra abonnemang",
    "confirmCancelTitle": "Avsluta abonnemang",
    "confirmCancelMessage": "Är du säker på att du vill avsluta ditt abonnemang? Abonnemanget kommer att löpa på till slutdatum men därefter inte förnyas.",
    "editSubscription": "Ändra abonnemang"
  },
  "adminPurchasesPage": {
    "tableTitle": "Faktureringsunderlag",
    "dateStart": "Från",
    "dateUntil": "Till",
    "exportButton": "Exportera",
    "actionInvoiceList": "Faktureringsunderlag",
    "actionCardPaymentsList": "Kortbetalningar",
    "actionSubscriptionsList": "Abonnemang",
    "editCommentAction": "Kommentar"
  },
  "customerSubscriptionOverview": {
    "subscription": "Abonnemang",
    "noSubscription": "Inget abonnemang",
    "quota": "Dokument kvar att använda",
    "price": "Pris",
    "dateFrom": "Gäller fr.o.m.",
    "nextRenewal": "Nästa förnyelse",
    "dateUntil": "Gäller t.o.m.",
    "nextSubscription": "Nästa abonnemang",
    "pricePerYear": "/ år",
    "pinsInSubscription": "Dokument i abonnemang",
    "pinsUnlimited": "Obegränsat",
    "priceMember": "Pris (medlem)"
  },
  "subscriptionPlans": {
    "plan": "{name}",
    "memberpriceSuffix": "(medlemspris) per år",
    "priceSuffix": "kr",
    "actionButton": "Välj",
    "numPinsValidFor": "Gäller för ",
    "numPinsUpTo": "upp till",
    "numPins": " {pins} dokument under ett år.",
    "saveUpTo": "Spara upp till ",
    "perYear": "/år",
    "plansSubTextContact": "Skriver ni över 2 000 formulär om året? Kontakta Katja Kalda för offert,",
    "plansSubTextContactEmail": "katja.kalda#fastighetsagarna.se.",
    "plansSubTextTerms": "Årsabonnemangen ger er möjlighet att under ett år skarpmarkera det antal dokument som ingår i abonnemangsnivån. Genom att skarpmarkera ett dokument kan det skrivas ut utan utkaststämpel. Vid utebliven uppsägning förlängs årsabonnemanget automatiskt ett år i taget. För fullständiga villkor se våra ",
    "termsTitle": "allmänna villkor.",
    "priceSuffixYear": "per år",
    "actionButtonDowngrade": "Nedgradera",
    "actionButtonUpgrade": "Uppgradera",
    "activePlan": "Aktiv plan"
  },
  "paymentReciept": {
    "titleInvoice": "Orderbekräftelse",
    "orderDate": "Datum",
    "orderNumber": "Referensnummer",
    "paymentMethod": "Betalningsmetod",
    "invoice": "Faktura",
    "vat": "Moms",
    "paymentAmount": "Att betala",
    "fsabAddressLine1": "Fastighetsägarna Sverige AB (556019-6387)",
    "fsabAddressLine2": "Box 16132, 103 23 Stockholm",
    "fsabAddressLine3": "Telefon: 08-613 57 00",
    "fsabAddressLine4": "E-post: info#fastighetsagarna.se",
    "invoiceReference": "Referens:",
    "titleReceipt": "Kvitto",
    "creditCard": "Kort",
    "paymentAmountPayed": "Betalt"
  },
  "swedbankPayCardCheckout": {
    "cancel": "Avbryt",
    "errorCreatingOrder": "Det gick inte att aktivera betalning",
    "loading": "Laddar...",
    "showReciept": "Visa kvitto",
    "okClose": "Återgå till ditt ärende",
    "paymentCompletedTitle": "Betalning genomförd!",
    "paymentCompletedText": "Betalningen lyckades och ditt ärende kan nu skrivas ut utan utkaststämpel. Kvitto på betalningen har också skickats till dig via e-post."
  },
  "editCaseTemplateDetails": {
    "title": "Mallärende",
    "nameLabel": "Namn*",
    "descriptionLabel": "Beskrivning",
    "cancel": "Avbryt",
    "save": "Spara",
    "saving": "Sparar...",
    "errorNameRequired": "Namn kan inte vara tomt",
    "infoMessage": "Med mallärenden kan du skapa egna mallar för vanligt förekommande kontraktsärenden. All data och tillagda bilagor som finns i mallen kommer att ligga till grund för de ärende som skapas baserat på mallen. Ett mallärende är tillängligt för alla användare i organisationen och alla användare kan också administrera mallen.",
    "templateUpgradeInfoMessage": "Ärendet innehåller äldre versioner av formulär. Följande formulär kommer uppdateras till senaste versionen när du skapar en mall från detta ärende. Du bör kontrollera innehållet i det nya mallärendet då ändringar i formulären har skett."
  },
  "listCaseTemplatesPage": {
    "confirmDeleteTitle": "Radera mallärende",
    "confirmDeleteMessage": "Är du säkert på att du vill radera det här mallärendet? Detta går inte att ångra.",
    "actionDelete": "Radera",
    "actionEdit": "Redigera",
    "actionEditName": "Ändra namn",
    "actionEditTemplate": "Redigera mall",
    "errorUsingTemplate": "Något gick fel. Ärendet kunde inte startas.",
    "errorDeletingTemplate": "Något gick fel. Mallärendet kunde inte raderas.",
    "lastUpdated": "Senast uppdaterad av",
    "searchPlaceholder": "Sök mallärende...",
    "listHeader": "Välj mallärende",
    "tableNoMatches": "Inga mallärenden matchar sökningen",
    "tableNoData": "Det finns inte några mallärenden skapade än. Du skapar mallärenden under <i>Mina ärenden</i> genom att på ett befintligt ärende välja <i>Spara som mall</i>.",
    "copyPreCheckTitle": "Skapa från mall",
    "copyPreCheckMessage": "Mallärendet innehåller äldre versioner av formulär. Följande formulär kommer att uppdateras till senaste versionen när du skapar ett ärende från den här mallen. Du bör kontrollera innehållet efter att du skapat ärendet eftersom ändringar i formulären har skett.",
    "copyItemAction": "Skapa ärende"
  },
  "adminPurchasesCardPaymentsPage": {
    "tableTitle": "Kortbetalningar"
  },
  "adminUserManageTab": {
    "deleteUser": "Radera användare",
    "currentPassword": "Ditt lösenord*",
    "currentUserFullName": "Användarens namn*",
    "deletingUser": "Tar bort användaren",
    "deleteUserInformationConfirm": "Detta går ej att ångra. Är du säker på att du vill radera användaren?",
    "userNameError": "Du måste bekräfta med användarens fulla namn",
    "knownError": "Kunde ej radera användaren. Kontrollera ditt lösenord och användarens namn.",
    "success": "Användaren är raderad",
    "unknownError": "Kunde ej radera användaren. Försök igen och kontakta supporten om felet kvarstår.",
    "sysadminError": "Du kan inte radera en användare som är support- eller systemadministratör",
    "orgAdminError": "Du kan inte radera en användare som är ägare eller admin i en organisation",
    "deleteUserInformation": "För att radera användaren måste du ange ditt lösenord och bekräfta med användarens namn.",
    "currentPasswordMissingMessage": "Ange nuvarande lösenord"
  },
  "baseSearchSelect": {
    "noData": "Inga träffar"
  },
  "adminAddOrgMemberModal": {
    "titel": "Lägg till användare",
    "confirmText": "Du kommer att lägga till '{user}' i organisationen '{org}'",
    "save": "Lägg till",
    "saving": "Lägger till...",
    "warningMessage": "Observera att du kan välja användare från hela systemet och inte bara för aktuell kund. En tillagd användare får direkt tillgång till organisationen."
  },
  "adminStatsPage": {
    "dashboard": "Översikt",
    "templates": "Mallanvändning",
    "tableTitle": "Mallar använda i skarpa ärenden"
  },
  "adminStatsTemplatesPage": {
    "tableTitle": "Dokument använda i skarpa ärenden",
    "tableNoItems": "Ingen data för angivet filter",
    "period7": "Senaste 7 dagarna",
    "period30": "Senaste 30 dagarna",
    "period90": "Senaste kvartalet",
    "period180": "Senaste halvåret",
    "period365": "Senaste 12 månaderna",
    "tableNoItemsNoTemplateSelected": "Ingen mall vald",
    "periodCustom": "Anpassad period"
  },
  "adminStatsDashboardPage": {
    "casesTitle": "Skarpa ärenden",
    "customerTitle": "Kunder",
    "organizationsTitle": "Organisationer",
    "usersTitle": "Användare",
    "cardPaymentsTitle": "Kortbetalningar",
    "total": "Totalt {total}",
    "subscriptionsNewTitle": "Abonnemang (nya)",
    "subscriptionsRenewedTitle": "Abonnemang (förnyelse)",
    "information": "* Ärenden avser skarpa ärenden",
    "subscriptionsTitle": "Abonnemang",
    "tooltipCurrentMonth": "Innevarande månad",
    "tooltipPreviousMonth": "Under hela föregående månad",
    "tooltipSinceStart": "Totalt sen start"
  },
  "customerStats": {
    "payedTemplates": "Betalda dokument",
    "casesTitle": "Skarpa ärenden",
    "esignTitle": "E-signeringar"
  },
  "moveOrganizationModal": {
    "titel": "Flytta organisation",
    "save": "Flytta",
    "saving": "Flyttar...",
    "cancel": "Avbryt",
    "confirmText": "Du kommer att flytta organisationen '{org}' till kunden '{cust}'",
    "warningMessage": "Varning. Detta kommando flyttar hela ägarskapet på organisationen till den kund du väljer."
  },
  "errorInvalidCaseUrl": {
    "title": "Det gick inte att öppna länken",
    "description": "Den här typen av länk fungerar bara en gång och dessutom bara under en kort tidsperiod. Be den som skickade länken till dig att skapa en ny."
  },
  "adminSigningsPage": {
    "manage": "Hantera signeringar",
    "statsOngoing": "Pågående signeringar",
    "statsOngoingNotUpdatedTheLastDay": "Pågående signeringar som inte uppdaterats senaste dagen",
    "refreshCaseSignings": "Uppdatera signeringar",
    "refreshCaseSigningsInfo": "Detta hämtar aktuell status från signeringstjänsten för alla pågående signeringar. Detta kan ta några minuter. Vill du fortsätta?",
    "refresh": "Uppdatera",
    "refreshing": "Uppdaterar...",
    "refreshError": "Kunde inte kontakta signeringstjänsten. Försök igen senare.",
    "refreshSuccess": "Uppdateringen är klar."
  },
  "pdfViewer": {
    "loading": "Laddar förhandsvisning...",
    "error": "Det gick inte att ladda förhandsvisningen"
  },
  "imageViewer": {
    "loading": "Laddar bild...",
    "error": "Det gick inte att ladda förhandsvisningen"
  },
  "adminSubscriptionsPage": {
    "tableTitle": "Abonnemang"
  },
  "admin": {
    "reports": {
      "categories": {
        "templates": "Mallar",
        "users": "Användare",
        "customers": "Kunder",
        "signing": "Signering"
      },
      "templatesTitle": "Mallaktivitetsöversikt",
      "templatesDescription": "En samlad vy över vilka avtalsmallar som frekvent används, med en dynamisk ranking som uppdateras baserat på ditt val av tidsperiod. Spåra ökning eller minskning i användning med integrerad trendanalys",
      "templateDetailsTitle": "Kundspecifik mallanvändning",
      "templateDetailsDescription": "Zooma in på användningsmönster för en specifik avtalsmall och se vilka kunder som använder den mest under en angiven tidsram",
      "userOrganisationsTitle": "Användare per organisation",
      "userOrganisationsDescription": "Listar användare tillsammans med de organisation de tillhör",
      "userCustomerContactsTitle": "Kontaktpersoner hos kund",
      "userCustomerContactsDescription": "Listar alla kontaktpersoner per kund. Både primär kontaktperson och administratörer visas.",
      "signingCustomersTitle": "Signeringsstatistik per kund",
      "signingCustomersDescription": "Antal signeringar per kund",
      "signingTemplatesTitle": "Signeringsstatistik per avtalsmall",
      "signingTemplatesDescription": "Antal signeringar per avtalsmall (som huvudavtal)"
    }
  },
  "adminReportsUserOrganisations": {
    "showAllFilter": "Visa alla",
    "showAllNonMembersFilter": "Visa enbart icke medlemmar"
  },
  "uploadFile": {
    "attachFile": "Bifoga fil",
    "updateFile": "Uppdatera fil",
    "fileTooLargeMax50MB": "Filen är för stor. Max 50MB.",
    "fileTypeInvalid": "Filtypen är ogiltig. Om filen är en av de filtyper som stöds (PDF, PNG, JPG, JPEG, DOC, DOCX, XLS, XLSX, PPT, PPTX), prova att ladda upp den på nytt.",
    "attachment": "Bifogad fil",
    "downloadFile": "Ladda ner fil",
    "downloadFailed": "Det gick inte att ladda ner filen"
  }
}